/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";

// Animation variables start ===============

// number of words - should be updated if you plan to add remove words
$words-count: 10;

$scale-val: 0.5;
$scale-val-mobile: 0.7;

// Header + slide down speed and easing
$header-slide: 1200; // in MS
$header-slide-easing: cubic-bezier(.54,0,0,1);

// word transition properties
$word-y: 18px; // word movement - EX: will move up 20px
$word-transition: 400; // word duration in ms;
$word-easing-y: cubic-bezier(0,0,.26,1);
$word-easing-opacity: cubic-bezier(0,0,.26,1);

// Delay between each word in ms; 
$word-delay: 30; // = 0.03s

// Pause before slideup in MS
$intro-pause: 0; 

// title scale and overlay fadeout
$intro-overlay: 1200; // in MS
// overlay + title scale + content slide up easing
$intro-overlay-easing: cubic-bezier(.54,0,0,1);

$intro-overlay-delay: ($word-transition + $intro-pause ) + ($word-delay * $words-count);

// Animation variables End ===============

.intro {
	transform-origin: bottom left;
	@media (min-width: $bp-x-large) {
        padding-top: 150px;
        padding-bottom: 60px;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-top: 150px;
        padding-bottom: 60px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding-top: 110px;
		padding-bottom: 60px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 110px;  
		padding-bottom: 50px;
    }
    @media (max-width: $bp-x-small) {
        padding-top: 60px;  
		padding-bottom: 40px;
    }
	height: calc((var(--vh, 1vh) * 100) - 90px);
	height: calc(100vh - 90px);
	transform: translateZ(0);
	transition: height #{$intro-overlay - 30}ms $intro-overlay-easing;
	will-change: height;
	#transition-val {
		position: fixed;
		left: -100vh;
		transition: transform $word-easing-y;
		transition-duration: #{$word-transition}ms;
		transition-delay:  #{$intro-overlay-delay}ms;
	}
	> .container {
		position: relative;
		z-index: 3;
		height: 100%;
		> .row {
			height: 100%;
		}
    }
    &:before {
		display: block;
		content:" ";
		position: absolute;
        top: -90px; 
		left: 0;
		width: 100%;
        height: 90px;
		background-color: $color-beige;
		z-index: 2;
    }
	#title-holder {
		transform: translate3d(0,0,0);
		overflow: hidden;
	}
	#intro-title {
		transform: translate3d(0,0,0);
		transform-origin: left top;
		overflow: hidden;
		width: 250vw;
		span {
			display: block;
			float: left;
			white-space: pre;
			@media (min-width: $bp-large-min){
				&:nth-child(3), &:nth-child(7){
					clear: left;
				}
			}
			@media (min-width: $bp-small-min) and (max-width: $bp-medium-max){
				&:nth-child(3), &:nth-child(5), &:nth-child(9){
					clear: left;
				}
			}
			@media (max-width: $bp-x-small){
				&:nth-child(3), &:nth-child(5), &:nth-child(9){
					clear: left;
				}
			}
		}
	}
	&:not(.no-intro-animation) {
		#title-holder {
			transition: #{$intro-overlay - 30}ms height $intro-overlay-easing;
			will-change: height;
		}
		#intro-title {
			transition: transform #{$intro-overlay}ms $intro-overlay-easing 0s;
			will-change: transform;
			span {
				opacity: 0;
				transform: translateY($word-y) translateZ(0);
			}
		}
		&:after {
			display: block;
			content:" ";
			position: absolute;
			top: -90px; 
			left: 0;
			width: 100vw;
			height: 200vh;
			background-color: $color-saumon;
			z-index: 2;
			opacity: 1;
		}
	}
	&.no-intro-animation {
		&:after {
			opacity: 0;
			visibility: hidden;
			transition: opacity 0s 0s, visibility 0s 0s;
			will-change: opacity, visibility;
		}
		#intro-title {
			transition: 0s 0s;
			&.scaling {
				transition: 0s 0s;
				@media (min-width: $bp-large-min){
					transform: translate3d(0,0,0) scale($scale-val);
				}
				@media (max-width: $bp-medium-max){
					transform: translate3d(0,0,0) scale($scale-val-mobile);
				}
				will-change: transform;
			}
			span {
				opacity: 1;
				transform: translateY(0) translateZ(0);
				transition: all 0s 0s !important;
				will-change: opacity, transform;
			}
			@for $i from 1 through $words-count {
				span:nth-child(#{$i}) {
					opacity: 1;
					transform: translateY(0) translateZ(0);
					transition: all 0s 0s;
					//transition: opacity #{$word-transition}ms $word-easing-opacity, transform #{$word-transition}ms $word-easing-y;
				}
			}
		}
		& + .worknews-section .container {
			opacity: 1;
			transition: opacity 0.3s ease 0.3s;
			will-change: opacity;
		}
	}
	&.animated:not(.no-intro-animation) {
		&:after {
			opacity: 0;
			visibility: hidden;
			width: 100%;
			transition: opacity #{$intro-overlay}ms $intro-overlay-easing #{$intro-overlay-delay}ms,
			visibility 0s #{($intro-overlay + $intro-overlay-delay)}ms, width 0s #{($intro-overlay + $intro-overlay-delay) - 20}ms;
			will-change: opacity;
		}
		#intro-title {
			&.scaling {
				@media (min-width: $bp-large-min){
					transform: translate3d(0,0,0) scale($scale-val);
				}
				@media (max-width: $bp-medium-max){
					transform: translate3d(0,0,0) scale($scale-val-mobile);
				}
			}
			@for $i from 1 through $words-count {
				span:nth-child(#{$i}) {
					opacity: 1;
					transform: translateY(0) translateZ(0);
					transition: opacity 0ms $word-easing-opacity, 
					transform #{$word-transition}ms $word-easing-y;
					transition-delay:  #{(0 + $word-delay) * $i}ms;
					will-change: opacity, transform;
					//transition: opacity #{$word-transition}ms $word-easing-opacity, transform #{$word-transition}ms $word-easing-y;
				}
			}
		}
		& + .worknews-section .container {
			opacity: 1;
			transition: opacity #{$intro-overlay}ms $intro-overlay-easing #{$intro-overlay-delay}ms;
			will-change: opacity;
		}
	}
	&.resize-ready {
		transition: 0s 0s;
		#intro-title {
			transition: 0s 0s;
		}
	}
	& + .worknews-section .container {
		opacity: 0;
	}
}