/*-----------------------------------------------------------------------------------*/
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";
@import "./fonts.scss";


.accordion-section{
	@media (min-width: $bp-x-large) {
        padding-top: 140px;
		padding-bottom: 140px;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-top: 130px;
		padding-bottom: 140px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding-top: 120px;
		padding-bottom: 120px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 100px;
		padding-bottom: 100px;
    }
    @media (max-width: $bp-x-small) {
        padding-top: 60px;
		padding-bottom: 60px;
    }

    .title{
        margin-bottom: 42px;
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            margin-bottom: 30px;
        }
        @media (max-width: $bp-x-small) {
            margin-bottom: 33px;
        }
    }

    p.accordion-desc{
        margin-bottom: 30px;
        @media (min-width: $bp-medium-min){
            max-width: 500px;
            margin-bottom: 40px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            max-width: 445px;
        }
    }
    .accordion-item{
        border-bottom: 1px solid $color-saumon;
        padding-top: 10px;
        padding-bottom: 10px;
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-top: 0px;
            padding-bottom: 0px;
            &.active{
                padding-bottom: 10px;
            }
        }

        @media (max-width: $bp-x-small) {
            padding-top: 2px;
            padding-bottom: 0px;
            &.active{
                padding-bottom: 20px;
            }
        }
        &:first-child{
            border-top: 1px solid $color-saumon;
        }

        &.active{
            border-bottom: 0px;
        }
    }
    .accordion-toggler{
        border: 0px;
        background: transparent;
        display: block;
        width: 100%;
        text-align: left;
        position: relative;
        padding: 16px 0 16px;
        z-index: 2;
        //cursor: none;
        color: $color-black;
        @media (max-width: $bp-x-small) {
            padding: 11px 0 11px;
        }
        &:focus{
            outline: none;
        }
        sup{
            font-size: 16px;
            letter-spacing: -0.1px;
            line-height: 1.5625;
            top: -50px;
            padding-left: 7px;
            font-family: $main-font;
            @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                top: -34px;
                padding-left: 10px;
            }
            @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                top: -32px;
                padding-left: 9px;
            }
            @media (max-width: $bp-x-small) {
                top: -18px;
                padding-left: 8px;
            }
        }
        .plus-icon{
            display: inline-block;
            position: absolute;
            right: 0;
            top: calc(50% + 0px);
            transform: translateY(-50%);
            width: 64px;
            height: 64px;
            @media (max-width: $bp-x-small) {
                top: calc(50% + 0px);

            }
            &:before, &:after{
                content: "";
                display: inline-block;
                position: absolute;
                right: 0px;
                top: 0;
                //transform: translateY(-50%);
                background-size: 64px 64px;
                background-position: center;
                width: 64px;
                height: 64px;
                background-image: url(./../ui/icon-minus-desktop.svg);
                transition: transform 500ms ease;
                transform-origin: center;

                @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                    top: calc(50% + -1px);
                }
                @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                    background-size: 42px 42px;
                    width: 42px;
                    height: 42px;
                    top: calc(50% + -1px);
                }
                @media (max-width: $bp-x-small) {
                    background-size: 29px 29px;
                    width: 29px;
                    height: 29px;
                    top: calc(50%);

                }
            }
            &:after {
              transform: rotate(90deg);
                @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                    transform: translateY(-50%) rotate(90deg);

                }
                @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                    transform: translateY(-50%) rotate(90deg);
                }
                @media (max-width: $bp-x-small) {
                    transform: translateY(-50%) rotate(90deg);
                }
            }
            &:before {
              transform: rotate(0deg);
                @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                    transform: translateY(-50%) rotate(0deg);

                }
                @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                    transform: translateY(-50%) rotate(0deg);
                }
                @media (max-width: $bp-x-small) {
                    transform: translateY(-50%) rotate(0deg);
                }
            }
        }


        &.active{
            .plus-icon{
                &:before{
                    /*opacity: 0;
                    visibility: hidden;*/
                    transform: rotate(-180deg);
                    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                        transform: translateY(-50%) rotate(-180deg);

                    }
                    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                        transform: translateY(-50%) rotate(-180deg);
                    }
                    @media (max-width: $bp-x-small) {
                        transform: translateY(-50%) rotate(-180deg);
                    }
                }
                &:after{
                    /*opacity: 1;
                    visibility: visible;*/
                    transform: rotate(0deg);
                    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                        transform: translateY(-50%) rotate(0deg);

                    }
                    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                        transform: translateY(-50%) rotate(0deg);
                    }
                    @media (max-width: $bp-x-small) {
                        transform: translateY(-50%) rotate(0deg);
                    }
                }
            }
        }
    }
    .ReactCollapse--collapse{
        transition: all 0.35s ease;
    }
    .accordion-body{
        padding-top: 23px;
        padding-bottom: 10px;
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-top: 16px;
        }

        @media (max-width: $bp-x-small) {
           padding-top: 1px;
        }
    }


    .job-list{
     padding-left: 0px;
     margin: 0px;
        li{
            list-style-type: none;
            display: block;
            padding-top: 0px;
            padding-bottom: 0px;
            a{
                display: block;
                width: 100%;
                text-decoration: none;
                color: inherit;
                position: relative;
                padding-top: 18px;
                padding-bottom: 19px;
                //cursor: none;
                @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                    padding-top: 20px;
                    padding-bottom: 20px;

                }
                @media (max-width: $bp-x-small) {
                    padding-top: 13px;
                    padding-bottom: 13px;

                }
                &:after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    background-image: url(./../ui/icon-arrow-bigbutton-mobile-blue.svg);
                    background-size: 17px;
                    background-position: center;
                    right: 0px;
                    transform: translateY(-50%);
                    top: 50%;
                    width: 17px;
                    height: 17px;
                    background-repeat: no-repeat;
                }

            }

            .job-title{
                align-self: center;
                @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                }
                @media (max-width: $bp-x-small) {
                }
            }
            p{
                align-self: center;
                @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                }
                @media (max-width: $bp-x-small) {
                }

            }

            border-top: 3px solid $color-yellow;
            &:last-child{
                border-bottom: 3px solid $color-yellow;
            }
        }
    }


}
