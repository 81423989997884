/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";


.image-section{
	
    img{
        width: 100%;
        max-width: 100%;

        height: auto;
    }
    &.sided-image{
        .item{
            &:first-child{
                @media (max-width: $bp-small-max){
                    padding-bottom: 30px;
                }
            }
        }
    }
        


}