/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";
@import "./fonts.scss";


.numberslist-section{
	@media (min-width: $bp-x-large) {
        padding-top: 130px;
		padding-bottom: 140px;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-top: 100px;
		padding-bottom: 120px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding-top: 120px;
		padding-bottom: 120px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 90px;
		padding-bottom: 100px;
		overflow: hidden;
    }
    @media (max-width: $bp-x-small) {
        padding-top: 50px;
		padding-bottom: 60px;
		overflow: hidden;
    }
    
    
    .item {
		@media (min-width: $bp-large-min){
			&:nth-child(n + 4) {
				padding-top: 70px;
			}
		}
	    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
			&:nth-child(n + 3) {
				padding-top: 80px;
			}
	    }
	    .p-big{
            @media (min-width: $bp-medium-min){
                padding-right: 30px;
            }
        }
    	.number{
    		margin-bottom: 25px;
		    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
		    	margin-bottom: 20px;
		    }
		    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
		    	margin-bottom: 30px;
		    }
		    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
		    	margin-bottom: 10px;
		    }
		    @media (max-width: $bp-x-small) {
				margin-bottom: 13px;

				@include typography(3.875, 1.1613, normal, -0.17, $heading-font);

		    }
    	}

    	a{
    		color: inherit;
    		text-decoration: none;
    		display: block;
    	}
        &:hover{
           
        }
    }

    // responsive styles 
    .container{
    	@media (max-width: $bp-small-max){
    		padding-left: 0px !important;
    		padding-right: 0px !important;
    	}
    }
    .list-row{
    	@media (max-width: $bp-small-max){
    		flex-wrap: inherit;
    		min-width: 100vw;
		    overflow-y: hidden;
		    white-space: nowrap;
		    scrollbar-width: none!important;
		    margin-left: 0px;
		    height: 100%;
		    overflow-x: scroll;
		    -webkit-overflow-scrolling: touch;
		    display: flex;

		    .item{
			    height: auto;
			    margin: 0;
			    padding-left: 0;
				padding-bottom: 0;
				padding-top: 0;
			    white-space: normal;
			    flex: 0 0 auto;
			    align-self: stretch;
		    }
		    &::-webkit-scrollbar {
                display: none;
            }
    	}

    	@media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
	        padding-left: $s-padding;
	        margin-right: 0px !important;
	        margin-left: 0px !important;
	        .item{
		    	width: calc((6 / 12 ) * (100vw - ( 8vw * 2 )));
			    padding-right: calc((1 / 12 ) * (100vw - ( 8vw * 2 )));
		    }
	    }
	    @media (max-width: $bp-x-small) {
	        padding-left: $xs-padding;
	        margin-right: 0px !important;
	        margin-left: 0px !important;
	        .item{
		    	width: calc((9 / 12 ) * (100vw - ( 5vw * 2 )));
			    padding-right: calc((1 / 12 ) * (100vw - ( 5vw * 2 )));
		    }
	    }

    }

}