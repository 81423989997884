/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";


.cursor-wrapper {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1005;
    pointer-events: none;
}
.cursor{
    @media (min-width: $bp-medium-min){
        position: absolute;
        pointer-events: none;
        z-index: 9999;
        border-radius: 50%;
        position: absolute;
        opacity: 1;
        .circle{
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 50%;
            width: 120px;
            height: 120px;
            transition: all 0.25s ease;
            border-radius: 50%;
            transform: translate(-50%, -50%);

            &:after{ // the left right arrows
                content: "";
                display: inline-block;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0px;
                top: 0px;
                background-position: center;
                background-repeat: no-repeat;
                transition: all 0.2s ease;
                background-size: 22px 22px;
                border-radius: 50%;

            }

            &:before{ // the circle background
                content: "";
                display: inline-block;
                position: absolute;
                left: 0%;
                top: 0%;
                width: 100%;
                height: 100%;
                transition: all 0.2s ease;
                border-radius: 50%;
                transform: scale((22 / 120));
            }
        }
        
        &.default-cursor{
            .circle{
                &:before{
                    border-radius: 50%;
                    background-color: $cursor-color;
                    transform: scale(calc(22 / 120));
                }
            }
            &.mouse-down{
                .circle{
                    &:before{
                        transform: scale(calc(18 / 120));
                    }
                }
            }
            &.large-cirlce{
                .circle{
                    &:before{
                        //transform: scale(calc(40 / 120));
                    }
                }
                &.mouse-down{
                    .circle{
                        &:before{
                            //transform: scale(calc(34 / 120));
                        }
                    }
                }
            }
        }

        &.arrow-cursor{
            .circle{
                &:before{
                    transform: scale(calc(100 / 120));
                }

            }
            &.blue-cursor{
                .circle{
                    &:before{
                        transform-origin: center;
                        background-color: $color-blue;
                    }
                    &:after{
                        background-image: url(./../ui/icon-arrow-bigbutton-cursor-white.svg);
                    }
                }
            }
            &.yellow-cursor{
                .circle{
                    &:before{
                        background-color: $color-yellow;
                        transform-origin: center;
                    }
                    &:after{
                        //background-color: $color-yellow;
                        background-image: url(./../ui/icon-arrow-bigbutton-cursor-blue.svg);
                    }
                }
            }
            &.left{  
                .circle{
                    &:after{
                        transform: rotate(-180deg);
                    }
                }
            }
            &.right{
                .circle{
                    &:after{
                        transform: rotate(0deg);
                    }
                }
            }
            &.force-right{
                .circle{
                    &:after{
                        transform: rotate(0deg) !important;
                    }
                }
            }
            &.force-left{  
                .circle{
                    &:after{
                        transform: rotate(-180deg) !important;
                    }
                }
            }

            &.mouse-down{
                .circle{
                    &:before{
                        transform: scale(calc(90 / 120));
                    }
                }
            }
        }

        &.drag-cursor{
            
            .circle{
                &:before{
                    background-color: $color-blue;
                    transform: scale(1)
                }
                .span-left, .span-right{
                    content: "";
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background-size: 14px 26px;
                    width: 14px;
                    height: 26px;
                    background-position: center;
                    background-repeat: no-repeat;
                    transition: all 0.2s ease;

                }
                .span-right{
                    background-image: url(./../ui/icon-arrow-drag-right.svg);
                    right: 33px;
                }
                .span-left{
                    background-image: url(./../ui/icon-arrow-drag-left.svg);
                    left: 33px;
                }
                
            }
            &.down{
                .circle{
                    &:before{
                        transform: scale(calc(100 / 120));
                    }
                    .span-right{
                        right: 27px;
                    }
                    .span-left{
                        left: 27px;
                    }

                }
            }
        }
    }
    @media (max-width: $bp-small-max){
        display: none;
    }

}

.static-curcor{
    pointer-events: none;
    border-radius: 50%;
    opacity: 1;
    .circle{
        display: inline-block;
        position: relative;
       
        width: 120px;
        height: 120px;
        transition: all 0.25s ease;
        border-radius: 50%;

        &:after{ // the left right arrows
            content: "";
            display: inline-block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            background-position: center;
            background-repeat: no-repeat;
            transition: all 0.2s ease;
            background-size: 22px 22px;
            border-radius: 50%;

        }

        &:before{ // the circle background
            content: "";
            display: inline-block;
            position: absolute;
            left: 0%;
            top: 0%;
            width: 100%;
            height: 100%;
            transition: all 0.2s ease;
            border-radius: 50%;
            transform: scale((22 / 120));
        }
    }
    
    &.default-cursor{
        .circle{
            &:before{
                border-radius: 50%;
                background-color: $cursor-color;
                transform: scale(calc(22 / 120));
            }
        }
        &.mouse-down{
            .circle{
                &:before{
                    transform: scale(calc(18 / 120));
                }
            }
        }
        &.large-cirlce{
            .circle{
                &:before{
                    //transform: scale(calc(40 / 120));
                }
            }
            &.mouse-down{
                .circle{
                    &:before{
                        //transform: scale(calc(34 / 120));
                    }
                }
            }
        }
    }

    &.arrow-cursor{
        .circle{
            &:before{
                transform: scale(calc(100 / 120));
            }

        }
        &.blue-cursor{
            .circle{
                &:before{
                    transform-origin: center;
                    background-color: $color-blue;
                }
                &:after{
                    background-image: url(./../ui/icon-arrow-bigbutton-cursor-white.svg);
                }
            }
        }
        &.yellow-cursor{
            .circle{
                &:before{
                    background-color: $color-yellow;
                    transform-origin: center;
                }
                &:after{
                    //background-color: $color-yellow;
                    background-image: url(./../ui/icon-arrow-bigbutton-cursor-blue.svg);
                }
            }
        }
        &.left{  
            .circle{
                &:after{
                    transform: rotate(-180deg);
                }
            }
        }
        &.right{
            .circle{
                &:after{
                    transform: rotate(0deg);
                }
            }
        }

        &.mouse-down{
            .circle{
                &:before{
                    transform: scale(calc(90 / 120));
                }
            }
        }
    }

    &.drag-cursor{
        
        .circle{
            &:before{
                background-color: $color-blue;
                transform: scale(1)
            }
            .span-left, .span-right{
                content: "";
                display: inline-block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-size: 14px 26px;
                width: 14px;
                height: 26px;
                background-position: center;
                background-repeat: no-repeat;
                transition: all 0.2s ease;

            }
            .span-right{
                background-image: url(./../ui/icon-arrow-drag-right.svg);
                right: 33px;
            }
            .span-left{
                background-image: url(./../ui/icon-arrow-drag-left.svg);
                left: 33px;
            }
            
        }
        &.down{
            .circle{
                &:before{
                    transform: scale(calc(100 / 120));
                }
                .span-right{
                    right: 27px;
                }
                .span-left{
                    left: 27px;
                }

            }
        }
    }
}

#wrapper.is-mobile{
    .cursor{
        display: none;
    }
}