/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";


.cta-with-images-section{
	@media (min-width: $bp-x-large) {
        padding-top: 140px;
		padding-bottom: 140px;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-top: 140px;
		padding-bottom: 140px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding-top: 120px;
		padding-bottom: 120px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 100px;
		padding-bottom: 100px;
    }
    @media (max-width: $bp-x-small) {
        padding-top: 60px;
		padding-bottom: 60px;
    }

   
    .images-container{
        backface-visibility: hidden;
        perspective: 1000;
        @media (min-width: $bp-x-large) {
            padding-bottom: 80px;
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            padding-bottom: 80px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            padding-bottom: 80px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-bottom: 60px;
        }
        @media (max-width: $bp-x-small) {
            padding-bottom: 40px;
        }
    }
    .image-item{
        background-position: center;
        background-size: cover;
        will-change: transform;
        backface-visibility: hidden;
        perspective: 1000;
        max-width: 100%;
        width: 100%;
        display: block;
        &.square-img{
            padding-top: 100%;
            margin-top: 125%;
            @media  (max-width: $bp-small-max) {
                margin-top: 150px;
            }
            @media (max-width: $bp-x-small){
                display: none;
            }

        }
        &.rec-img{
            padding-top: 59.6%
        }
        img{
            width: 100%;
            display: block;
            opacity: 1;

        }
    }
     
    .title{
        @media (min-width: $bp-x-large) {
            margin-bottom: 40px;
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            margin-bottom: 40px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            margin-bottom: 40px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            margin-bottom: 30px;
        }
        @media (max-width: $bp-x-small) {
            margin-bottom: 30px;
        }
    }
        

     .placeholder-img{
        opacity: 0;
        //width: 100%;
        //height: auto;
    }
    .lazy-holder {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img{
            height: 100%;
            width: 100%;
        }
    }

}