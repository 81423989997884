
@font-face {
  font-family: 'agrandir';
  src: url('./../fonts/agrandir-regular.eot');
    src: url('./../fonts/agrandir-regular.eot?#iefix') format('embedded-opentype'),
        url('./../fonts/agrandir-regular.woff2') format('woff2'),
        url('./../fonts/agrandir-regular.woff') format('woff'),
        url('./../fonts/agrandir-regular.ttf') format('truetype');
    font-display: swap;
}

$heading-font : agrandir, sans-serif;
$main-font : 'Inter', sans-serif;

$t-fs: 1em;
$t-lh: 1;
$t-fw: 400;
$t-ls: normal;
$t-font-family: $heading-font;
@mixin typography($t-fs, $t-lh, $t-fw, $t-ls, $t-font-family){
    font-size: #{$t-fs}em;
    line-height: $t-lh;
    font-weight: $t-fw;
    font-family: $t-font-family;
    @if $t-ls == normal {
        letter-spacing: $t-ls;
    } @else {
        letter-spacing: #{$t-ls}px;
    }

}
