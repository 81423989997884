/*-----------------------------------------------------------------------------------*/ 
/*  0. include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";

/*-----------------------------------------------------------------------------------*/ 
/*  1. Footer
/*-----------------------------------------------------------------------------------*/

//animation start - orange screen slideup - in ms
$page-transition-slideup: 700; 
$page-transition-slideup-easing: cubic-bezier(0.47,0.19,0.16,0.95);

//Columns animation - in ms
$page-transition-columns: 700; 
$page-transition-columns-easing: cubic-bezier(0.47,0.19,0.16,0.95);

//animation end - fadeout - in ms
$page-transition-fadeout: 700; 
$page-transition-fadeout-easing: cubic-bezier(0.47,0.19,0.16,0.95);

//page content animation when pageTransition animation is done
$page-transition-title-slideup: 40px;
$page-transition-title-easing: cubic-bezier(0.47,0.19,0.16,0.95);
$page-transition-content-slideup: 70px;
$page-transition-content-easing: cubic-bezier(0.47,0.19,0.16,0.95);

.page-transition {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0%;
	left: 0;
	z-index: 999999;
	opacity: 0;
	visibility: hidden;
	transition: opacity #{$page-transition-fadeout}ms $page-transition-fadeout-easing 0s, visibility 0s #{$page-transition-fadeout}ms;
	.inner-holder {
		position: absolute;
		left: 0;
		top: 100%;
		height: 100%;
		width: 100%;
		margin: 0;
		//transition: top 0s #{(($page-transition-columns + $page-transition-slideup) + $page-transition-fadeout)}ms;
		.pole {
	   	    height: 100%;
            opacity: 0;
			&:after, &:before {
				content: "";
			 	display: block;
			 	position: absolute;
			 	right: -1px;
				top: 0;
			 	height: 100%;
			 	width: 2px;
			 	background-color: $color-orange;
			}
			/*&:before {
				content: "";
			 	display: block;
			 	position: absolute;
			 	left: -1px;
				top: 0;
			 	height: 100%;
			 	width: 2px;
			 	background-color: #FF9D68 !important;
			}*/
        }
        /*&.background-yellow {
            .pole {
                background-color: $color-orange !important;
                &:before, &:after {
                    background-color: $color-orange !important;
                }
            }
        }
        &.background-beige {
            .pole {
                background-color: $color-green !important;
                &:before, &:after {
                    background-color: $color-green !important;
                }
            }
        }*/
        &.palette-homepage {
            .pole {
                background-color: $color-saumon !important;
                &:before, &:after {
                    background-color: $color-saumon !important;
                }
            }
        }
        &.palette-careers {
            .pole {
                background-color: $color-saumon !important;
                &:before, &:after {
                    background-color: $color-saumon !important;
                }
            }
        }
        &.palette-about {
            .pole {
                background-color: $color-orange !important;
                &:before, &:after {
                    background-color: $color-orange !important;
                }
            }
        }
        &.palette-work {
            .pole {
                background-color: $color-green !important;
                &:before, &:after {
                    background-color: $color-green !important;
                }
            }
        }
        &.palette-news {
            .pole {
                background-color: $color-saumon !important;
                &:before, &:after {
                    background-color: $color-saumon !important;
                }
            }
        }
        &.palette-contact {
            .pole {
                background-color: $color-green !important;
                &:before, &:after {
                    background-color: $color-green !important;
                }
            }
        }
        &.palette-faq {
            .pole {
                background-color: $color-orange !important;
                &:before, &:after {
                    background-color: $color-orange !important;
                }
            }
        }
    }
    &.transition-animate-reset {
        .inner-holder {
            transition: top 0s #{(($page-transition-columns + $page-transition-slideup) + $page-transition-fadeout)}ms;
        }
    }
	&.transition-animate {
        opacity: 1;
        visibility: visible;
        transition: opacity 0s 0s, visibility 0s 0s;
        .inner-holder {
            top: 0;
            transition: top #{$page-transition-slideup}ms $page-transition-slideup-easing 0s !important;
            /*.pole {
                opacity: 1;
                animation: pageTransition #{$page-transition-columns}ms #{$page-transition-slideup}ms 1;
                animation-fill-mode: forwards;
            }*/
        }
        &.transition-pole-start {
            .inner-holder {
                .pole {
                    opacity: 1;
                    animation: pageTransition #{$page-transition-columns}ms #{$page-transition-slideup}ms 1;
                    animation-fill-mode: forwards;
                }
            }
        }
    }
}

.page-animation-start {
    header {
        transform: translateY(-100%);
    }
    .top-section, .intro {
        .row {
            transform: translateY($page-transition-title-slideup);
            transition: transform 0s #{$page-transition-slideup}ms;
        }
        & + section {
            transform: translateY($page-transition-content-slideup);
            transition: transform 0s #{$page-transition-slideup}ms;
        }
    }
}

header {
    transition: transform #{$page-transition-fadeout}ms $page-transition-title-easing 0s;
}

.intro.no-intro-animation {
	.row {
		transition: transform #{$page-transition-fadeout}ms $page-transition-title-easing 0s;
    }
    & + section {
        transition: transform #{$page-transition-fadeout}ms $page-transition-title-easing 0s;
    }
}

.top-section {
	.row {
		transition: transform #{$page-transition-fadeout}ms $page-transition-title-easing 0s;
    }
    & + section {
        transition: transform #{$page-transition-fadeout}ms $page-transition-title-easing 0s;
    }
}

@keyframes pageTransition {
    0%   {
        clip-path: polygon(0 0, 101% 0, 101% 100%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 101% 0, 101% 100%, 0% 100%);
    }
    40%  {
        clip-path: polygon(0 0, 101% 0, 101% 0%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 101% 0, 110% 0%, 0% 100%);
    }
    50%  {
        clip-path: polygon(0 0, 101% 0, 101% 0%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 101% 0, 101% 0%, 0% 100%);
    }
    60%  {
        clip-path: polygon(0 0, 101% 0, 101% 0%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 101% 0, 101% 0%, 0% 100%);
    }
    100% {
        clip-path: polygon(0 0, 101% 0, 101% 0%, 0% 0%);
        -webkit-clip-path: polygon(0 0, 101% 0, 101% 0%, 0% 0%);
    }
  }
