/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";


.worknews-section{
    @media (min-width: $bp-x-large) {
        padding-top: 140px;
        padding-bottom: 140px;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media (max-width: $bp-x-small) {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .titles{
        padding-top: 30px;
        position: relative;
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-top: 40px;
        }
        @media (max-width: $bp-x-small) {
            padding-top: 20px;
        }
        .desc{
            @media (min-width: $bp-x-large){
                padding-right: 30px;
            }
            @media (min-width: $bp-medium-min){
                opacity: 0;
                position: absolute;
                visibility: hidden;
                top: 30px;
                left: 0px;
                width: 100%;
                height: 100%;
            }
            @media (max-width: $bp-small-max) {
                display: none;
            } 
        }
        .title{
            @media (min-width: $bp-x-large){
                padding-right: 100px;
            }
        }
        &.with-summary:hover {
            @media (min-width: $bp-large-min){
                .title{
                    opacity: 0 !important;
                    visibility: hidden;
                }
                .desc{
                    opacity: 1 !important;
                    visibility: visible;
                }
            }
        }
    }

    .work-item {
        .client-label {
            p {
                padding-top: 30px;
                display: inline-block;
            }
            & + a {
                .titles{
                    padding-top: 10px;
                    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                        padding-top: 10px;
                    }
                    @media (max-width: $bp-x-small) {
                        padding-top: 10px;
                    }
                    .desc{
                        @media (min-width: $bp-medium-min){
                            top: 10px;
                        }
                        @media (max-width: $bp-small-max) {
                            display: none;
                        } 
                    }
                }
            }
        }
    }

    .image-wrapper{
        position: relative;
        img{
            will-change: transform;
            backface-visibility: hidden;
            perspective: 1000;
            max-width: 100%;
            width: 100%;
            display: block;
        }
        .image-item{
            background-position: center;
            background-size: cover;
        }
        .label{
            position: absolute;
            bottom: -1px;
            left: 0px;
            padding-right: 15px;
            padding-top: 15px;
            padding-bottom: 1px;
            font-size: 1em;
            letter-spacing: -0.1px;
            line-height: 0.6875;
            //opacity: 0;
            //visibility: hidden;
            text-transform: capitalize;
            //transition: opacity 0.3s ease, transform 0.3s ease;
            @media (min-width: $bp-large-min){
               // transform: translateY(50%);

            }
            @media (max-width: $bp-medium-max){
               // opacity: 1;
                //visibility: visible;
            }
        }
        background-color: transparent !important; 
        &:before {
            content: "";
            width: calc(100% - 1px);
            height: calc(100% - 1px);
            top: 0;
            left: 0;
            position: absolute;
        }
        &.background-blue{
            &:before{
                background-color: $color-blue;
            }
        }
        &.background-saumon{
            &:before{
                background-color: $color-saumon;
            }
        }
        &.background-yellow{
            &:before{
                background-color: $color-yellow;
            }
        }
        &.background-orange{
            &:before{
                background-color: $color-orange;
            }
        }
        &.background-green{
            &:before{
                background-color: $color-green;
            }
        }
        &.background-beige{
            &:before{
                background-color: $color-beige;
            }
        }
    }
    

    .item{
        backface-visibility: hidden;
        perspective: 1000;
        a{
            color: inherit;
            text-decoration: none;
        }
       
        @media (min-width: $bp-x-large) {
            padding-bottom: 80px;
            &:last-child:nth-child(even), &:nth-last-child(2):nth-child(odd){
                padding-bottom: 0px;
            }
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            padding-bottom: 80px;
            &:last-child:nth-child(even), &:nth-last-child(2):nth-child(odd){
                padding-bottom: 0px;
            }
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            padding-bottom: 80px;
            &:last-child:nth-child(even), &:nth-last-child(2):nth-child(odd){
                padding-bottom: 0px;
            }
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-bottom: 60px;
            &:last-child{
                padding-bottom: 0px;
            }
            
        }
        @media (max-width: $bp-x-small) {
            padding-bottom: 40px;
            &:last-child{
                padding-bottom: 0px;
            }
        }
        

        &:hover{
            @media (min-width: $bp-medium-min){
                .label{
                    //opacity: 1;
                    //transform: translateY(0);
                }
            }
            
                
        }
    }

    .load-more-btn {
        margin-top: 80px;
        @media (max-width: $bp-x-small){
            margin-top: 40px;
        }
    }

    .item-wrapper{
        &.change-img{
            //opacity: 0;
            //transition: all 0.25s ease;
            img{
                transition: 0s !important;
            }
        }
        &.load-more--result {
            .titles, .client-label, span.label {
                opacity: 0;
            }
            &.holder-loaded {
                .titles, .client-label, span.label {
                    opacity: 1;
                }
            }
        }
    }
    .filter-holder {
        margin-top: 30px;
        padding-left: 0px;
        margin-bottom: 0px;
        li{
            list-style-type: none;
            display: inline-block;
            margin-top: 10px;
            &:not(:last-child){
                margin-right: 7px;
            }
        }
        

        @media (min-width: $bp-x-large) {
            padding-bottom: 70px;
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            padding-bottom: 70px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            padding-bottom: 60px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-bottom: 60px;
        }
        @media (max-width: $bp-x-small) {
            padding-bottom: 60px;
        }
    }
     

    .placeholder-img{
        opacity: 0;
        width: 100%;
        height: auto;
    }
    .lazy-holder {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img{
            height: 100%;
            width: 100%;
        }
    }

    
}