/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";


.video-section{
    overflow: hidden;
    .player-wrapper{
       // position: relative;

        .react-player {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
	&.full-video{
        .player-wrapper.others{
            height: calc( 54 / 100 * 100vw ) !important;
        }
    }

    &.medium-video{
        .player-wrapper.others{
           height: calc( 54 / 100 * 88vw ) !important;
        }
        @media (max-width: $bp-x-small){
            height: calc( 54 / 100 * 90vw ) !important;
        }
    }
    &.sided-video{
        .player-wrapper.others{
            height: calc( 54 / 100 * 44vw ) !important;
            @media (min-width: $bp-small-min) and (max-width: $bp-small-max){
               height: calc( 54 / 100 * 88vw ) !important;
            }
            @media (max-width: $bp-x-small){
                height: calc( 54 / 100 * 90vw ) !important;
            }
        }
        .item{
            &:first-child{
                @media (max-width: $bp-small-max){
                    padding-bottom: 30px;
                }
            }
        }
    }
    
    .video-overlay{
        cursor: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        //pointer-events: none;

    }
    .wistia{
        .react-player{
            //height: auto !important;
            & > div {
                //height: auto !important;
                position: relative !important;
            }
        }

        .w-video-wrapper {
            position: relative !important;
        }
    }
    
    .w-video-wrapper video, video {
        height: auto !important;
        /*width: 100% !important;
        height: auto !important;
        position: absolute !important;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);*/
    }
    
        


}