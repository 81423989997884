/*!-----------------------------------------------------------------------------------
  Theme Name: Madeo website
  Theme URI: https://madeostudio.com
  Description: Madeo Website
  Author: MADEO
  Author URI: http://madeostudio.com
  Version: 1.0
-------------------------------------------------------------------------------------*/


/*-----------------------------------------------------------------------------------*/
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";

/*-----------------------------------------------------------------------------------*/
/*  1. Import fonts
/*-----------------------------------------------------------------------------------*/
@import "./fonts.scss";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

/*-----------------------------------------------------------------------------------*/
/*  2. Import files css
/*-----------------------------------------------------------------------------------*/
@import "~bootstrap/scss/bootstrap";


/*-----------------------------------------------------------------------------------*/
/*  3. Globals
/*-----------------------------------------------------------------------------------*/
#content{min-height: 100vh}

.container {
    width: 100%;
    //padding-left: $col;
    //padding-right: $col;
    @media (min-width: $bp-x-large) {
        padding-right: $xl-padding;
        padding-left: $xl-padding;
        &:not(.no-max){
            max-width: calc(1500px + 12%);
        }
        &.no-max {
            max-width: 100%;
        }
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-right: $l-padding;
        padding-left: $l-padding;
        max-width: 100%;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding-right: $m-padding;
        padding-left: $m-padding;
        max-width: 100%;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-right: $s-padding;
        padding-left: $s-padding;
        max-width: 100%;
    }
    @media (max-width: $bp-x-small) {
        padding-right: $xs-padding;
        padding-left: $xs-padding;
        max-width: 100%;
    }
    .row {
        @media (min-width: $bp-x-large) {
            margin-right: $xl-row;
            margin-left: $xl-row;
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            margin-right: $l-row;
            margin-left: $l-row;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            margin-right: $m-row;
            margin-left: $m-row;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            margin-right: $s-row;
            margin-left: $s-row;
        }
        @media (max-width: $bp-x-small) {
            margin-right: $xs-row;
            margin-left: $xs-row;
        }
    }
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
        @media (min-width: $bp-x-large) {
            padding-right: $xl-col;
            padding-left: $xl-col;
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            padding-right: $l-col;
            padding-left: $l-col;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            padding-right: $m-col;
            padding-left: $m-col;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-right: $s-col;
            padding-left: $s-col;
        }
        @media (max-width: $bp-x-small) {
            padding-right: $xs-col;
            padding-left: $xs-col;
        }
    }
}

.container-fluid {
    width: 100%;
    .row {
        @media (min-width: $bp-x-large) {
            padding-right: $xl-row;
            padding-left: $xl-row;
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            padding-right: $l-row;
            padding-left: $l-row;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            padding-right: $m-row;
            padding-left: $m-row;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-right: $s-row;
            padding-left: $s-row;
        }
        @media (max-width: $bp-x-small) {
            padding-right: $xs-row;
            padding-left: $xs-row;
        }
    }
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
        @media (min-width: $bp-x-large) {
            padding-right: $xl-col;
            padding-left: $xl-col;
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            padding-right: $l-col;
            padding-left: $l-col;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            padding-right: $m-col;
            padding-left: $m-col;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-right: $s-col;
            padding-left: $s-col;
        }
        @media (max-width: $bp-x-small) {
            padding-right: $xs-col;
            padding-left: $xs-col;
        }
    }
}


html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: normal;
  font-size: 16px;
  background-color: $color-beige;
}

body {
    font-family: $main-font;
    font-weight: normal;
    font-style: normal;
    line-height: 1.6;
    font-size: 16px;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    color: $color-black;
    background-color: $color-beige;
    &.tab-active {
        *:focus {
            // outline: 2px solid #7AACFE !important; /* for non-webkit browsers */
                outline: -webkit-focus-ring-color auto 5px !important;
        }
    }
    &.StyleGuide{
        background-color: $color-white;
        .header-content{
            background-color: $color-white;
        }
    }
}

#wrapper {
    position: relative;
    //overflow: hidden;
    //overflow-x: hidden !important;
}


/*-----------------------------------------------------------------------------------*/
/*  4. Background & font colors
/*-----------------------------------------------------------------------------------*/
.background-black{
    background-color: $color-black !important;
}
.background-white{
    background-color: $color-white !important;
    &:not(.border-item):not(.quoteslider-section):not(header):not(.fixed-section) + .background-white{
        padding-top: 0px !important;
    }
}
.background-beige{
    background-color: $color-beige !important;

    &:not(.border-item):not(.quoteslider-section):not(header):not(.fixed-section) + .background-beige{
        padding-top: 0px !important;
    }

    &.dragableslider-section + &.logoslider-section {
        .section-title{
            padding-top: 40px;
            @media (max-width: $bp-x-small){
                padding-top: 20px;
            }
        }
    }
}
.background-yellow{
    background-color: $color-yellow !important;
    &:not(.border-item):not(.quoteslider-section):not(header):not(.fixed-section) + .background-yellow{
        padding-top: 0px !important;
    }
}
.background-saumon{
    background-color: $color-saumon !important;
    &:not(.border-item):not(.quoteslider-section):not(header):not(.fixed-section) + .background-saumon{
        padding-top: 0px !important;
    }
}
.background-orange{
    background-color: $color-orange !important;
    &:not(.border-item):not(.quoteslider-section):not(header):not(.fixed-section) + .background-orange{
        padding-top: 0px !important;
    }
}
.background-green{
    background-color: $color-green !important;
    &:not(.border-item):not(.quoteslider-section):not(header):not(.fixed-section) + .background-green{
        padding-top: 0px !important;
    }
}
.background-blue{
    background-color: $color-blue !important;
    &:not(.border-item):not(.quoteslider-section):not(header):not(.fixed-section) + .background-blue{
        padding-top: 0px !important;
    }
}

.color-white {
    color: $color-white !important;
}
.color-black {
    color: $color-black !important;
}
.color-beige {
    color: $color-beige !important;
}
.color-yellow {
    color: $color-yellow !important;
}
.color-saumon {
    color: $color-saumon !important;
}
.color-orange {
    color: $color-orange !important;
}
.color-green {
    color: $color-green !important;
}
.color-blue {
    color: $color-blue !important;
}


/*-----------------------------------------------------------------------------------*/
/*  5. Typography
/*-----------------------------------------------------------------------------------*/

h1,h2,h3,h4,h5,h6,p {
    margin: 0;

}



//$heading-font : 'Inter', sans-serif;
//$main-font : 'Inter', sans-serif;

@mixin h1(){  // x-large title
    @media (min-width: $bp-x-large) {
        @include typography(5.625, 1.1, normal, -0.25, $heading-font);
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        @include typography(5, 1.1, normal, -0.2, $heading-font);
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        @include typography(4.375, 1.1, normal, -0.2, $heading-font);
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        @include typography(3.875, 1.043, normal, -0.15, $heading-font);
    }
    @media (max-width: $bp-x-small) {
        @include typography(2.4375, 1.15385, normal, -0.1, $heading-font);
    }
    a{
        color: $color-black;
        text-decoration: none;
        border-bottom: 1px solid;
        border-color: inherit;
        transition: 0s ease;
        &:hover{
            @media (min-width: $bp-medium-min){
                color: $color-blue;
                border-color: $color-blue;
            }
        }
        &:hover{
            text-decoration: none;
            @media (max-width: $bp-small-max) {
                color: $color-black;
            }
        }
    }

}
@mixin h2(){  // large-title
    @media (min-width: $bp-x-large) {
        @include typography(4.187, 1.1045, normal, -0.2, $heading-font);
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        @include typography(3.75, 1.1667, normal, -0.18, $heading-font);
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        @include typography(3.25, 1.1923, normal, -0.16, $heading-font);
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        @include typography(2.875, 1.2174, normal, -0.1, $heading-font);
    }
    @media (max-width: $bp-x-small) {
        @include typography(2.125, 1.2353, normal, -0.09, $heading-font);
    }
    a{
        color: $color-black;
        text-decoration: none;
        border-bottom: 1px solid;
        border-color: inherit;
        transition: 0s ease;
        &:hover{
            @media (min-width: $bp-medium-min){
                color: $color-blue;
                border-color: $color-blue;
            }
        }
        &:hover{
            text-decoration: none;
            @media (max-width: $bp-small-max) {
                color: inherit;
            }
        }
    }

}
@mixin h3(){  //big-title
    @media (min-width: $bp-x-large) {
        @include typography(2.5, 1.25, normal, -0.8, $main-font);
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        @include typography(2.5, 1.25, normal, -0.8, $main-font);
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        @include typography(2.25, 1.31, normal, -0.72, $main-font);
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        @include typography(2.125, 1.32353, normal, -0.7, $main-font);
    }
    @media (max-width: $bp-x-small) {
        @include typography(1.5625, 1.36, normal, -0.3, $main-font);
    }
    a{
        color: $color-black;
        text-decoration: none;
        border-bottom: 1px solid;
        border-color: inherit;
        transition: 0s ease;
        &:hover{
            @media (min-width: $bp-medium-min){
                color: $color-blue;
                border-color: $color-blue;
            }
        }
        &:hover{
            text-decoration: none;
            @media (max-width: $bp-small-max) {
                color: inherit;
            }
        }
    }
}
@mixin h4(){  // medium-title
    @media (min-width: $bp-x-large) {
        @include typography(1.625, 1.462, normal, -0.3, $main-font);
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        @include typography(1.625, 1.462, normal, -0.3, $main-font);
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        @include typography(1.5, 1.5, normal, -0.25, $main-font);
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        @include typography(1.5, 1.5, normal, -0.25, $main-font);
    }
    @media (max-width: $bp-x-small) {
        @include typography(1.375, 1.455, normal, -0.25, $main-font);
    }
    a{
        color: $color-black;
        text-decoration: none;
        border-bottom: 1px solid;
        border-color: inherit;
        transition: 0s ease;
        &:hover{
            @media (min-width: $bp-medium-min){
                color: $color-blue;
                border-color: $color-blue;
            }
        }
        &:hover{
            text-decoration: none;
            @media (max-width: $bp-small-max) {
                color: inherit;
            }
        }
    }
}
@mixin h5(){ // small title
    @media (min-width: $bp-x-large) {
        @include typography(1.25, 1.55, normal, -0.15, $main-font);
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        @include typography(1.25, 1.55, normal, -0.15, $main-font);
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        @include typography(1.25, 1.55, normal, -0.15, $main-font);
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        @include typography(1.25, 1.55, normal, -0.15, $main-font);
    }
    @media (max-width: $bp-x-small) {
        @include typography(1.125, 1.556, normal, -0.2, $main-font);
    }
    a{
        color: $color-black;
        text-decoration: none;
        border-bottom: 1px solid;
        border-color: inherit;
        transition: 0s ease;
        &:hover{
            @media (min-width: $bp-medium-min){
                color: $color-blue;
                border-color: $color-blue;
            }
        }
        &:hover{
            text-decoration: none;
            @media (max-width: $bp-small-max) {
                color: inherit;
            }
        }
    }
}

$p-type: normal;
@mixin paragraph($p-type){
    @if ($p-type == big) {
        @media (min-width: $bp-x-large) {
            @include typography(1.25, 1.55, normal, -0.15, $main-font);
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            @include typography(1.25, 1.55, normal, -0.15, $main-font);
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            @include typography(1.25, 1.55, normal, -0.15, $main-font);
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            @include typography(1.25, 1.55, normal, -0.15, $main-font);
        }
        @media (max-width: $bp-x-small) {
            @include typography(1.125, 1.556, normal, -0.2, $main-font);
        }
        a{
        }
    }
    @if $p-type == normal {
        @media (min-width: $bp-x-large) {
            @include typography(1, 1.5625, normal, -0.1, $main-font);
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            @include typography(1, 1.5625, normal, -0.1, $main-font);
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            @include typography(1, 1.5625, normal, -0.1, $main-font);
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            @include typography(1, 1.5625, normal, -0.1, $main-font);
        }
        @media (max-width: $bp-x-small) {
            @include typography(1, 1.5625, normal, -0.1, $main-font);
        }
        a{
        }
    }
    @if $p-type == small {
        @media (min-width: $bp-x-large) {
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        }
        @media (max-width: $bp-x-small) {
        }
    }

    @if $p-type == caption {
        @media (min-width: $bp-x-large) {
            @include typography(0.8125, 1.5385, normal, -0.15, $main-font);
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            @include typography(0.8125, 1.5385, normal, -0.15, $main-font);
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            @include typography(0.8125, 1.5385, normal, -0.15, $main-font);
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            @include typography(0.8125, 1.5385, normal, -0.15, $main-font);
        }
        @media (max-width: $bp-x-small) {
            @include typography(0.8125, 1.5385, normal, -0.15, $main-font);
        }
    }


    a{
        color: $color-blue;
        text-decoration: none;
        border-bottom: 1px solid;
        border-color: inherit;
        transition: 0s ease;



        &:hover{
            @media (min-width: $bp-medium-min){
                color: $color-blue;
                border-color: $color-blue;
            }
        }
        &:hover{
            text-decoration: none;
            @media (max-width: $bp-small-max) {
                color: inherit;
            }
        }
    }

}

.x-large-title {
    @include h1();
}

.large-title {
    @include h2();
}

.big-title {
    @include h3();
}

.medium-title {
    @include h4();
}

.small-title {
    @include h5();
}

p {
    //&:not(.p-big):not(.p-small):not(.p-caption):not(.wp-caption-text){
       @include paragraph($p-type);
    //}
    &.p-big {
        @include paragraph(big);
    }
    &.p-small {
        @include paragraph(small);
    }
    &.p-caption, &.wp-caption-text{
        @include paragraph(caption);
        position: relative;
    }

    &:not(:last-child):not(.caption){
        margin-bottom: 30px;
        @media (max-width: $bp-small-max) {
            margin-bottom: 25px;
        }
    }
}

@mixin ul(){
    padding-left: 0;
    padding-bottom: 0;
    font-size: 16px;
    ul{
        padding-left: 0;
        padding-bottom: 0;
        font-size: 16px;
        margin-top: 20px;
    }
    li{
        @include paragraph(big);
        list-style-type: none;
        padding-left: 28px;
        position: relative;
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0px;
        }
        @media (max-width: $bp-x-small){
            padding-left: 20px;
        }
        &:before{
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            position: absolute;
            left: 0;
            background-color: $color-blue;
            border-radius: 50%;
            top: 9px;
            @media (min-width: $bp-x-large){
                top: 13px;
            }
            @media (min-width: $bp-medium-min) and (max-width: $bp-large-max){
                top: 9.5px;
            }
            @media (min-width: $bp-small-min) and (max-width: $bp-small-max){
                top: 9.5px;
            }
            @media (max-width: $bp-x-small){
                top: 7.5px;
            }
        }
        strong, b{
            font-weight: 600;
        }
        ul {
            li{
                &:before{
                    border: 1px solid $color-blue;
                    background-color: transparent;
                }
            }
        }
    }
}
.default-ul{
    @include ul();
}


@mixin ol(){
    padding-left: 0;
    padding-bottom: 0;
    font-size: 16px;
    counter-reset: my-awesome-counter;
    ol {
        padding-left: 0;
        padding-bottom: 0;
        font-size: 16px;
        margin-top: 20px;
    }
    li{
        @include paragraph(big);
        list-style-type: none;
        padding-left: 28px;
        position: relative;
        margin-bottom: 20px;
        counter-increment: my-awesome-counter;
        position: relative;
        &:before {
            content: counter(my-awesome-counter);
            font-weight: bold;
            position: absolute;
            top: 0;
            left: 0;
            text-align: center;
        }
        &:last-child{
            margin-bottom: 0px;
        }
        @media (max-width: $bp-x-small){
            padding-left: 20px;
        }
        strong, b{
            font-weight: 600;
        }
        ol {
            li{
                &:before{
                    border: 1px solid $color-blue;
                    background-color: transparent;
                }
            }
        }
    }
}
.default-ol{
    @include ol();
}


@mixin blockquote(){
    @include paragraph(big);
    position: relative;
    padding-left: 28px;
    @media(min-width: $bp-medium-min){
        max-width: 528px;
    }
    p{
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        font-family: inherit;
    }
    &:before{
        content: "";
        position: absolute;
        top: 7px;
        left: 0px;
        bottom: 7px;
        width: 2px;
        //width: 2px;
        display: inline-block;
        background-color: $color-blue;
        @media (max-width: $bp-x-small) {
            top: 8.5px;
        }
    }
}

.blockquote{
    @include blockquote();
}
/*
  Buttons
*/
$btn_type: big_btn;
@mixin buttons($btn_type){
    &:focus{
        outline: none;
    }
    @if ($btn_type == big_btn) {
        position: relative;
        display: inline-block;
        border: 0px;
        //transition: 0.3s ease;
        transition: 0.2s ease-out;
        @media (min-width: $bp-large-min) {
            @include typography(1.625, 1.19231, normal, -0.3, $main-font);
            padding: 20px 65px 20px 30px;
            border-radius: 68.5px;
            &:hover{
                &:not(.load-more) {
                    padding-right: 75px;
                }
                &.load-more {
                    &:after {
                        transform: translateY(-50%) scale(1.00001) rotate(90deg);
                    }
                }
            }
        }

        @media (min-width: $bp-small-min) and (max-width: $bp-medium-max) {
            @include typography(1.5, 1.5, normal, -0.25, $main-font);
            border-radius: 68.5px;
            padding: 17px 65px 16px 30px;
            &:hover{
                &:not(.load-more) {
                    padding-right: 75px;
                }
                &.load-more {
                    &:after {
                        transform: translateY(-50%) scale(1.00001) rotate(90deg);
                    }
                }
            }
        }

        @media (max-width: $bp-x-small) {
            @include typography(1.125, 1.556, normal, -0.2, $main-font);
            padding: 10px 50px 10px 25px;
            border-radius: 47.79px;
        }
        &:after, &:before{
            content: "";
            display: inline-block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%) scale(1.00001);
            @media (min-width: $bp-small-min) {
                width: 23px;
                height: 24px;
                right: 20px;
            }
            @media (max-width: $bp-x-small) {
                width: 16px;
                height: 17px;
                right: 15px;
            }
        }
        &.load-more {
            &:before {
                display: none !important;
            }
            &:after {
                transition: transform 0.25s 0s;
                transform-origin: center center;
                @media (min-width: $bp-small-min) {
                    width: 22px;
                    height: 22px;
                }
                @media (max-width: $bp-x-small) {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        &:hover{
          text-decoration: none;
          @media (min-width: $bp-small-min) {
            &:not(.load-more) {
                &:after{
                opacity: 0;
                }
            }
            &:before{
              opacity: 1
            }
          }
        }
        &.blue-btn{
            background-color: $color-blue;
            color: $color-white;
            &.load-more {
                &:after, &:before {
                    background-image: url(./../ui/icon-plus-white.svg);
                    background-size: contain;
                }
            }
            &:not(.load-more) {
                @media (min-width: $bp-small-min) {
                    &:after{
                        background-image: url(./../ui/icon-arrow-bigbutton-white.svg);
                    }
                    &:before{
                        background-image: url(./../ui/icon-arrow-bigbutton-yellow.svg);
                    }
                }
                @media (max-width: $bp-x-small) {
                    &:after, &:before{
                        background-image: url(./../ui/icon-arrow-bigbutton-mobile-white.svg);
                    }
                }
            }
        }
        &.white-btn{
          background-color: $color-white;
          color: $color-black;

          @media (min-width: $bp-small-min) {
            &:after{
              background-image: url(./../ui/icon-arrow-bigbutton-blue.svg);
            }
            &:before{
              background-image: url(./../ui/icon-arrow-bigbutton-green.svg);
            }
          }
          @media (max-width: $bp-x-small) {
              &:after, &:before{
                background-image: url(./../ui/icon-arrow-bigbutton-mobile-blue.svg);
              }
          }
        }

    }

    @if ($btn_type == medium_btn) {
        position: relative;
        display: inline-block;
        border: 0px;
        //transition: 0.3s ease;
        transition: 0.2s ease-out;
        @include typography(1.125, 1.556, normal, -0.2, $main-font);
        padding: 10px 48px 10px 25px;
        border-radius: 47.79px;

        @media (min-width: $bp-medium-min) {
            &:hover{
                padding-right: 58px;
            }
        }
        &:after, &:before{
            content: "";
            display: inline-block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            height: 17px;
            right: 20px;
        }
        &:hover{
          text-decoration: none;
          @media (min-width: $bp-small-min) {
            &:after{
              opacity: 0;
            }
            &:before{
              opacity: 1
            }
          }
        }


        &.blue-btn{
            background-color: $color-blue;
            color: $color-white;
            @media (min-width: $bp-small-min) {
                &:after{
                    background-image: url(./../ui/icon-arrow-bigbutton-mobile-white.svg);
                }
                &:before{
                    background-image: url(./../ui/icon-arrow-bigbutton-mobile-yellow.svg);
                }
            }
            @media (max-width: $bp-x-small) {
              &:after, &:before{
                background-image: url(./../ui/icon-arrow-bigbutton-mobile-white.svg);
              }
            }
        }
        &.white-btn{
          background-color: $color-white;
          color: $color-black;

          @media (min-width: $bp-small-min) {
            &:after{
                background-image: url(./../ui/icon-arrow-bigbutton-mobile-blue.svg);
            }
            &:before{
                background-image: url(./../ui/icon-arrow-bigbutton-mobile-green.svg);
            }
          }
          @media (max-width: $bp-x-small) {
              &:after, &:before{
                background-image: url(./../ui/icon-arrow-bigbutton-mobile-blue.svg);
              }
          }
        }

    }
    @if $btn_type == small_btn {
        @include typography(1, 1.625, normal, -0.1, $main-font);
        color: $color-blue;
        border: 1px solid rgba($color-blue, 0.2);
        padding: 4px 20px;
        border-radius: 18px;
        transition: 0.3s ease;
        //transition: 0.2s ease-out;
        display: inline-block;
        &:hover{
            border: 1px solid rgba($color-blue, 1);
            text-decoration: none;
        }
        &.active{
          color: $color-white;
          background-color:  $color-blue;
          text-decoration: none;
        }
        &.blue-btn {
            color: $color-white;
            background-color:  $color-blue;
        }


    }

}

.button{
  &.big-btn{
      @include buttons(big_btn);
  }
  &.medium-btn{
      @include buttons(medium_btn);
  }
  &.small-btn{
      @include buttons(small_btn);
  }
}


/*
==========
QUOTE WITH CAPTION Style
=========
*/
@mixin quote(){
    @media (min-width: $bp-x-large) {
        @include typography(3.5, 1.1786, normal, -0.17, $heading-font);
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        @include typography(3.5, 1.1786, normal, -0.17, $heading-font);
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        @include typography(3.25, 1.19231, normal, -0.16, $heading-font);
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        @include typography(2.875, 1.2174, normal, -0.1, $heading-font);
    }
    @media (max-width: $bp-x-small) {
        @include typography(2.125, 1.2353, normal, -0.09, $heading-font);
    }
}
.quote{
    @include quote();
}

@mixin quote-caption(){
    @media (min-width: $bp-x-large) {
        @include typography(1.5, 1.5, normal, -0.25, $main-font);
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        @include typography(1.5, 1.5, normal, -0.25, $main-font);
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        @include typography(1.5, 1.5, normal, -0.25, $main-font);
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        @include typography(1.5, 1.5, normal, -0.25, $main-font);
    }
    @media (max-width: $bp-x-small) {
        @include typography(1.125, 1.556, normal, -0.11, $main-font);
    }
}
.quote-caption{
    @include quote-caption();
}





.pos-relative{
   // position: relative;
}

//fixed section for background transition
.fixed-section {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: background 0.5s ease;
}
@mixin text-link() {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid;
    position: relative;
    color: inherit;
    background-color: transparent;
    text-decoration: none;
    -webkit-transition: background .15s cubic-bezier(.33,.66,.66,1);
    -o-transition: background .15s cubic-bezier(.33,.66,.66,1);
    transition: background .15s cubic-bezier(.33,.66,.66,1);
    -webkit-box-shadow: inset 0 -4px 0 $color-yellow, 0 3px 0 $color-yellow;
    box-shadow: inset 0 -4px 0 $color-yellow, 0 3px 0 $color-yellow;
    overflow-wrap: break-word;
    word-break: break-word;
    word-wrap: break-word;
    border-bottom: 0 none !important;
    color: $color-black !important;
    &:hover{
        text-decoration: none;
        color: inherit;
        background-color: $color-yellow;
        -webkit-box-shadow: inset 0 -4px 0 $color-yellow, 0 3px 0 $color-yellow, 0 -2px 0 $color-yellow;
        box-shadow: inset 0 -4px 0 $color-yellow, 0 3px 0 $color-yellow, 0 -2px 0 $color-yellow;
        /*@media (min-width: $bp-medium-min){
            color: $color-blue;
            border-color: $color-blue;
        }*/
    }
    &:hover{
        text-decoration: none;
        @media (max-width: $bp-small-max) {
            color: inherit;
        }
    }
}
.text-link{
    @include text-link();
}
.p-big{
    .text-link{
        -webkit-box-shadow: inset 0 -5px 0 $color-yellow, 0 3px 0 $color-yellow;
        box-shadow: inset 0 -5px 0 $color-yellow, 0 3px 0 $color-yellow;
          &:hover{
            text-decoration: none;
            color: inherit;
            background-color: $color-yellow;
            -webkit-box-shadow: inset 0 -5px 0 $color-yellow, 0 3px 0 $color-yellow, 0 -2px 0 $color-yellow;
            box-shadow: inset 0 -5px 0 $color-yellow, 0 3px 0 $color-yellow, 0 -2px 0 $color-yellow;
            /*@media (min-width: $bp-medium-min){
                color: $color-blue;
                border-color: $color-blue;
            }*/
        }
    }
}


.paragraph-section{
    h1{
        @include h1();
    }
    h2{
        @include h2();
    }
    h3{
        @include h3();
    }
    h4{
        @include h4();
    }
    h5, h6{
        @include h5();
    }

    ul:not(.cat-list){
        @include ul();
    }

    ol:not(.cat-list){
        @include ol();
    }

    p{
        @include paragraph(big);
        a{
            @include text-link();

            -webkit-box-shadow: inset 0 -5px 0 $color-yellow, 0 3px 0 $color-yellow;
            box-shadow: inset 0 -5px 0 $color-yellow, 0 3px 0 $color-yellow;
              &:hover{
                text-decoration: none;
                color: inherit;
                background-color: $color-yellow;
                -webkit-box-shadow: inset 0 -5px 0 $color-yellow, 0 3px 0 $color-yellow, 0 -2px 0 $color-yellow;
                box-shadow: inset 0 -5px 0 $color-yellow, 0 3px 0 $color-yellow, 0 -2px 0 $color-yellow;
                /*@media (min-width: $bp-medium-min){
                    color: $color-blue;
                    border-color: $color-blue;
                }*/
            }
        }

    }
    blockquote{
        @include blockquote();
    }
}


#wrapper, header, footer, section, .accordion-toggler{
    @media (min-width: $bp-medium-min){
        cursor: none;

        *{
            cursor: none;
        }
        button, .swiper-pagination-bullet, [role=button]{
            cursor: none !important;
        }
    }

}
#page-transition {
    cursor: default;
    *{
        cursor: default;
    }
}

.section-404{
    .row{
        @media (min-width: $bp-small-min){
            min-height: calc(100vh - 90px);
            padding-top: 100px;
            padding-bottom: 100px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max){
            min-height: calc(100vh - 65px);
        }
        @media(max-width: $bp-x-small){
            padding-top: 140px;
            padding-bottom: 140px;
        }
    }

    .title{
        @media (min-width: $bp-small-min){
                    margin-bottom: 18px;

        }
        @media(max-width: $bp-x-small){
            margin-bottom: 12px;
        }
    }
    p.desc {
        margin-bottom: 0px !important;
        & + .button-wrap{
            margin-top: 30px;
        }
    }

}

.faq-section {
    .accordion-body {
        p {
            @include paragraph(big);
        }
        ul {
            @include ul();
        }
        ol {
            @include ol();
        }
    }
}
.homepage-block {
    #wrapper {
        //visibility: hidden;
        section:not(.intro), #main-header, .main-footer {
            opacity: 0;
        }
        section.intro {
            .container {
                opacity: 0;
            }
        }
    }
}
.homepage-bg {
    background-color: $color-saumon !important;
}

.pattern-page{
    section{
        opacity: 1 !important;
    }
}

/* Categories list in work page */

@mixin cat-list(){
    padding-left: 0;
    padding-bottom: 0;
    font-size: 16px;
    li{
        @include paragraph(normal);
        list-style-type: none;
        padding-left: 23px;
        position: relative;
        margin-bottom: 5px;
        &:last-child{
            margin-bottom: 0px;
        }
        a{
            color: inherit;
            border-color: inherit;
        }
        @media (max-width: $bp-x-small){
        }
        &:before{
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            position: absolute;
            left: 0;
            background-color: $color-saumon;
            border-radius: 50%;
            top: 8.5px;
            /*@media (min-width: $bp-x-large){
                top: 8px;
            }
            @media (min-width: $bp-medium-min) and (max-width: $bp-large-max){
                top: 9px;
            }
            @media (min-width: $bp-small-min) and (max-width: $bp-small-max){
                top: 9.5px;
            }
            @media (max-width: $bp-x-small){
                top: 7.5px;
            }*/
        }
        strong, b{
            font-weight: 600;
        }
    }

    @media (max-width: $bp-small-max){
        display: flex;
        max-width: 100%;
        flex-wrap: wrap;
        li{
            flex: 0 0 50%;
            max-width: 50%;

            &:last-child, &:nth-last-child(2){
                margin-bottom: 0px;
            }
        }
    }

}
.cat-list {
    @include cat-list();
}

.passwordprotectedform {
    padding-top: 100px;
    padding-bottom: 100px;
    .form-holder {
        //max-width: 435px;
        position: relative;
         margin-top: 20px;
         padding-bottom: 15px;
         border-bottom: 1px solid $color-blue;
         @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
             margin-bottom: 60px;
         }
         @media (max-width: $bp-x-small) {
             margin-bottom: 30px;
         }
         input {
             @media (min-width: $bp-large-min) {
                 @include typography(1, 1.5625, normal, -0.1, $main-font);
             }
             @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                 @include typography(1, 1.5625, normal, -0.1, $main-font);
             }
             @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                 @include typography(1, 1.5625, normal, -0.1, $main-font);
             }
             @media (max-width: $bp-x-small) {
                 @include typography(1, 1.5625, normal, -0.1, $main-font);
             }
             width: calc(100% - 30px);
             height: 26px;
             padding: 0;
             background: transparent none !important;
             border-radius: 0 !important;
             box-shadow: inherit !important;
             border: inherit !important;
             outline: inherit !important;
             color: $color-blue;
             &::placeholder {
                 color: $color-black;
             }
             &:-ms-input-placeholder {
                 color: $color-black;
             }
             &::-ms-input-placeholder {
                 color: $color-black;
             }
             &:hover, &:focus {
                 color: $color-blue;
                 &::placeholder {
                     color: $color-blue;
                 }
                 &:-ms-input-placeholder {
                     color: $color-blue;
                 }
                 &::-ms-input-placeholder {
                     color: $color-blue;
                 }
             }
         }
         button {
             position: absolute;
             top: 5px;
             right: 0;
             background: no-repeat url(./../ui/icon-arrow-bigbutton-mobile-blue.svg) right center transparent;
             transition: 0.35s;
             background-size: 16px 16px;
             width: 26px;
             height: 16px;
             border-radius: 0 !important;
             box-shadow: inherit !important;
             border: inherit !important;
             outline: inherit !important;
             text-indent: -99999px;
         }
     }
}

.page-single-news.single-news .paragraph-section .row {
    justify-content: center;
}
