/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";


.cta-with-title-section{
	@media (min-width: $bp-x-large) {
        padding-top: 120px;
		padding-bottom: 160px;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-top: 120px;
		padding-bottom: 160px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding-top: 120px;
		padding-bottom: 120px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 100px;
		padding-bottom: 100px;
    }
    @media (max-width: $bp-x-small) {
        padding-top: 60px;
		padding-bottom: 60px;
    }
    &.no-padding{
        padding-top: 0px;
        padding-bottom: 0px;
    }

  
    .title{
        @media (min-width: $bp-x-large) {
            margin-bottom: 40px;
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            margin-bottom: 40px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            margin-bottom: 30px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            margin-bottom: 30px;
        }
        @media (max-width: $bp-x-small) {
            margin-bottom: 20px;
        }
    }
        

     
}