/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";


.contactinfo-section{
	@media (min-width: $bp-x-large) {
        padding-top: 140px;
		padding-bottom: 140px;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-top: 120px;
		padding-bottom: 120px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding-top: 120px;
		padding-bottom: 120px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 100px;
		padding-bottom: 100px;
    }
    @media (max-width: $bp-x-small) {
        padding-top: 60px;
		padding-bottom: 60px;
    }
    a{
    	color: inherit;
    	border-bottom: 6px solid $color-yellow !important;
    	text-decoration: none;

    	&:hover{
    		color: $color-black !important;
            border-bottom: 10px solid $color-yellow !important;
            text-decoration: none;
    	}
    }
    .big-title{
        p {
            line-height: inherit !important;
            font-size: inherit !important;
            letter-spacing: inherit !important;
        }
        &:not(:last-child){
        	margin-bottom: 40px;
        	@media (max-width: $bp-x-small) {
    	        margin-bottom: 30px;
    	    }
        }
    	a{
    		padding-bottom: 2px;
    	}
    }

    .medium-title{
    	a{
    		padding-bottom: 1px;
    	}
    }
    

}