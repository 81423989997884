/*! Variables
   ========================================================================== */

// Colors ==================
$color-black: 	#000;
$color-white: 	#fff;
$color-beige: 	#F7F2E7;
$color-yellow:  #FFF8A4;
$color-saumon:  #FFD4A5; // Peach color
$color-orange:  #FF9D68;
$color-green: 	#21CE99;
$color-blue: 	#184EAC;

$cursor-color: 	#01F9CB;

 



// Grids ==================

$container: 100px;
$container-fluid: 32px;
$xl-padding: 6%;
$xl-col: 25px;
$xl-row: -$xl-col;


$l-padding: 6%;
$l-col: 20px;
$l-row: -$l-col;

$m-padding: 6%;
$m-col: 17.5px;
$m-row: -$m-col;

$s-padding: 6%;
$s-col: 15px;
$s-row: -$s-col;

$xs-padding: 5%;
$xs-col: 10px;
$xs-row: -$xs-col;


$bp-x-large: 1200px;
$bp-large-max: 1199.98px;
$bp-large-min: 992px;
$bp-medium-max: 991.98px;
$bp-medium-min: 768px;
$bp-small-max: 767.98px;
$bp-small-min: 576px;
$bp-x-small: 575.98px;
