/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";
@import "~slick-carousel/slick/slick.css";



.largeslider-section{
    position: relative;
    z-index: 1;
  @media (min-width: $bp-x-large) {
        padding-top: 90px;
      padding-bottom: 90px; //calc( 90px + 38px);
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-top: 90px;
        padding-bottom: 90px; //calc( 90px + 38px);
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding-top: 90px;
        padding-bottom: 90px; //calc( 90px + 38px);
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 70px;
    padding-bottom: 70px; //calc( 70px + 33px);
    }
    @media (max-width: $bp-x-small) {
        padding-top: 60px;
        padding-bottom: 60px; //calc( 60px + 20px);
    }


    &:focus{
        outline: none !important;
    }
    *{
        &:focus{
            outline: none !important;
        }
    }
    
    a{
      color: inherit;
      text-decoration: none;
    }
    
    .item-content{
        width: 100%;
        height: 100%;        
    }
    
    .slick-slide {
        transition: 0s !important;
        /*@media (min-width: $bp-medium-min){
            opacity: 1 !important;
        }*/
        @media (min-width: $bp-large-min){
            opacity: 1 !important;
        }

        .item-content{
            @media (min-width: $bp-large-min){
                opacity: 0;
                &.active{
                    opacity: 1;
                }
            }
            /*@media (min-width: $bp-medium-min){
                opacity: 0;
                &.active{
                    opacity: 1;
                }
            }*/
                
        }
    }
    .item-content{
        width: 100%;
        height: 100%;
       /* &.swiper-slide-active {
            z-index: 99;
            pointer-events: auto;
            pointer-events: auto;
        }*/
        .image-holder{
            width: 100%;
            padding-top: 59.2%;
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            -webkit-transition-property: -webkit-transform;
            transition-property: -webkit-transform;
            -o-transition-property: transform;
            transition-property: transform;
            transition-property: transform,-webkit-transform;
            will-change: transform;
            perspective: 1000;



            .img{
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              width: 100%;
              display: block;
              left: 0;
              right: 0;
              position: absolute;
              top: 0;
              height: 100%;
              backface-visibility: hidden;
              -webkit-backface-visibility: hidden;
              perspective: 1000;

              
              -webkit-transition-property: -webkit-transform;
              transition-property: -webkit-transform;
              -o-transition-property: transform;
              transition-property: transform;
              transition-property: transform,-webkit-transform;


            }
        }
    }


    .slick-arrow{
        position: absolute;
        top: 0px;
        height: calc( 100% - 50px );
        width: 50%;
        border: 0px;
        background-color: transparent;
        z-index: 999;
        color: transparent;
        font-size: 0px;
        &:focus{
            outline: none;
        }

        &.slick-prev{
            left: 0px;
        }
        &.slick-next{
            right: 0px;
        }
    }

    .slick-dots{
        overflow: hidden;
        padding-left: 0px;
        margin-bottom: 0px;
        font-size: 1em;
        line-height: 1;
        //margin-bottom: -7.5px;
        //left: 50%;
        //transform: translateX(-50%);
        //position: absolute;
        //cursor: pointer;
        @media (min-width: $bp-x-large) {
            //bottom: -38px;
            padding-top: 22.5px;
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            //bottom: -38px;
            padding-top: 22.5px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            //bottom: -38px;
            padding-top: 22.5px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            //bottom: -33px;
            padding-top: 17.5px;
        }
        @media (max-width: $bp-x-small) {
            //bottom: -28px;
            padding-top: 12.5px;
        }
        li{
            display: inline-block;
            list-style-type: none;
            font-size: 1em;
            line-height: 1;
            cursor: pointer;
            button{
                font-size: 0px;
                width: 23px;
                height: 23px;
                border: 0px;
                padding: 0px;
                margin: 0px;
                background: transparent;
                position: relative;
                cursor: pointer;

                &:after{
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $color-orange;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                &:focus{
                    outline: none;
                }
            }

            &.slick-active, &:hover{
                button{
                    &:after{
                        background-color: $color-blue;
                    }
                }
            }
        }
        
    }


    .placeholder-img{
        opacity: 0;
        width: 100%;
        height: auto;
    }
    .lazy-holder {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img{
            /*height: 100%;
            width: 100%;*/


            min-width: 100%;
            max-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            object-fit: cover;
            align-self: center;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .normal-cursor {
        position: absolute;
        width: calc(50vw - (8/12 * 100% / 2));
        height: 100%;
        &.left{
            left: 0;
        }
        &.right{
            right: 0;
        }

        @media (max-width: $bp-medium-max){
            display: none;  
        }
        /*@media (max-width: $bp-small-max){
            display: none;  
        }*/
    }

}

#wrapper{
    .largeslider-section{
        @media (max-width: $bp-medium-max){
            cursor: auto;
            *{
                cursor: auto;
            }
        }
    }
}

