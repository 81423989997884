/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";


.paragraph-section{
	
    img{
        width: 100%;
        max-width: 100%;

        height: auto;
    }
    h1, h2{
        margin-bottom: 40px;
        margin-top: 60px;
        @media (max-width: $bp-small-max) and (min-width: $bp-small-min){
            //margin-bottom: 30px;
        }
        @media (max-width: $bp-x-small){
            margin-bottom: 25px;
            margin-top: 40px;
        }
        &:last-child{
            margin-bottom: 0px;
        }
         &:first-child{
            margin-top: 0px;
        }

        a{
           // color: $color-blue;
        }

    }
    h3, h4, h5, h6, ul{
        margin-bottom: 30px;
        margin-top: 50px;
        @media (max-width: $bp-small-max) and (min-width: $bp-small-min){
          //  margin-bottom: 30px;
        }
        @media (max-width: $bp-x-small){
            margin-bottom: 25px;
            margin-top: 45px;
        }
        &:last-child{
            margin-bottom: 0px;
        }
         &:first-child:not(.cat-list){
            margin-top: 0px;
        }

        a{
           // color: $color-blue;
        }

    }
    p{
        a{
           // color: $color-blue;
        }
        iframe {
            width: 100% !important;
            height: calc(0.5 * 83.33333vw) !important;
        }
        .iframe-holder {
            position: relative;
        }
        .iframe-overlay{
            position: absolute;
            top: 0px;
            height: 100%;
            width: 100%;
            left: 0px
        }
        strong, b{
            font-weight: 600;
        }
    }

    .cta-holder{
        &:not(:first-child) {
           margin-top: 30px;
        }
    }

    .cat-list{
        margin-top: 0px;
        @media (max-width: $bp-small-max){

            margin-top: 30px;
        }

    }

    
}

