/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";
@import "~slick-carousel/slick/slick.css";

@import '~swiper/swiper.scss';
//@import "~swiper/css/swiper.css";


.dragableslider-section{
    position: relative;
    overflow: hidden;
    
   
    @media (min-width: $bp-x-large) {
        padding-top: 140px;
        padding-bottom: 80px; //140px;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-top: 120px;
        padding-bottom: 60px; //120px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
         padding-top: 120px;
        padding-bottom: 60px; //120px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 100px;
        padding-bottom: 40px; //100px;
    }
    @media (max-width: $bp-x-small) {
        padding-top: 60px;
        padding-bottom: 40px; //60px;
    }
    &.no-padding{
      padding-top: 0px;
      padding-bottom: 0px;
    }
    a{
      color: inherit;
      text-decoration: none;
    }
   
    .img-wrap{
      transition: 0.25s ease;
      position: relative;
      transform-origin: top center;
    }
    /*&.mouseDown{
      @media (min-width: $bp-medium-min){
        .img-wrap{ 
            transform: scale(0.95);
        }
      }
        
    }*/

    .swiper-container{
      overflow: visible;
      padding-bottom: 0px !important;
    }
    .slick-list{
      overflow: visible;
    }
    .item{
      position: relative;
      display: inline-block;
      width: auto;
      flex-shrink: inherit !important;
      .img-wrap{ 
          display: inline-block;
      }
      &:focus{
        outline: none;
      }
      @media (min-width: $bp-x-large) {
        padding-left: 15px;
        padding-right: 15px;
      }
      @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
          padding-left: 15px;
        padding-right: 15px;
      }
      @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
          padding-left: 15px;
        padding-right: 15px;
      }
      @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
          padding-left: 12px;
        padding-right: 12px;
      }
      @media (max-width: $bp-x-small) {
        padding-left: 10px;
        padding-right: 10px;
      }
      img{
        //max-height: 500px;
        //max-width: 650px;
        @media (min-width: $bp-x-large) {
          max-width: 450px;
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            max-width: 400px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            max-width: 350px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            max-width: 300px;
        }
        @media (max-width: $bp-x-small) {
          max-width: 250px;
        }




        @media (max-width: $bp-small-max) {
          max-height: 400px;
        }
        @media(max-width: $bp-x-small){
         // max-width: 350px;
        }

      }
      
      .p-caption{
        opacity: 0;
        max-width: 400px;

        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;

        @media (min-width: $bp-large-min){
            /*opacity: 0;
            transform: translateY(8px);*/
            transition: 0.35s ease;
            //visibility: hidden;
            //max-width: 400px;
            //position: absolute;
            //width: calc(100% - 30px);
            //top: calc(100% + 20px);
            //left: 15px;
            //padding-bottom: 60px;
            //width: fit-content;
            padding-top: 20px;
            

        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
          //margin-top: 20px;
          //max-width: 400px;
          //width: 100%;
          //position: absolute;
          //left: 15px;
          //width: calc(100% - 30px);
          //top: calc(100% + 20px);

          //width: fit-content;
          padding-top: 20px;


        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
          //margin-top: 20px;
          //max-width: 400px;
          //width: 100%;
          //position: absolute;
          //left: 12px;
          //width: calc(100% - 24px);
          //top: calc(100% + 20px);

          //width: fit-content;
          padding-top: 20px;
        }
        @media (max-width: $bp-x-small) {
          //margin-top: 10px;
          //max-width: 400px;
          //width: 100%;
          //position: absolute;
          //left: 10px;
          //width: calc(100% - 20px);
          //top: calc(100% + 10px);
          
          //width: fit-content;
          padding-top: 10px;
        }
        
        &.show-in{
          opacity: 1;
          //transition-delay: 0.2s;
          transition: 0s 0.2s;
        }

      }
      &:hover{
        .fully-in{
          & + .p-caption{
             /* opacity: 1;
              transform: translateY(0);*/
              //visibility: visible;
          }
        }
      }
    }

    .swiper-wrapper{
        @media (min-width: $bp-x-large) {
          margin-left: -15px;
          margin-right: -15px;
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            margin-left: -15px;
          margin-right: -15px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            margin-left: -15px;
          margin-right: -15px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            margin-left: -12px;
          margin-right: -12px;
        }
        @media (max-width: $bp-x-small) {
          margin-left: -10px;
          margin-right: -10px;
        }
    }



    .placeholder-img{
        opacity: 0;
        //width: 100%;
        //height: auto;
    }
    .lazy-holder {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img{
            height: 100%;
            width: 100%;
        }
    }


    /* swiper buttons*/
    .swiper-button-prev{
      display: none;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: -6vw;
      width: 50vw;
      height: 100%;
      z-index: 4;
      &:focus{
        outline: none;
      }
      /*&.full-btn{
        width: 100vw;
        z-index: 9
      }*/
      &.swiper-button-disabled{
       z-index: 0
      }
    }
    .swiper-button-next{
      display: none;pointer-events: none;
      position: absolute;
      top: 0;
      right: -6vw;
      width: 50vw;
      height: 100%;
      z-index: 2;
      &:focus{
        outline: none;
      }
      @media (min-width: $bp-large-min){
          width: 100vw;
          &.swiper-button-disabled{
            & + .swiper-button-prev{
              width: 100vw;
            }
          }

      }
      /*&.full-btn{
        width: 100vw;
        z-index: 9
      }*/
      &.swiper-button-disabled{
        z-index: 0;
        & + .swiper-button-prev{
          //width: 100vw;
        }
      }
     
    }
    
}




