/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";


// transision easing
$logo_ease: transform 0s ease;
// vetical space movement
$vertical_Space : translateY(0px);
/// ========= ======= ====



.logoslider-section{
    &:not(.bg-transition){
        position: relative;
    }
    //position: relative;
    @media (min-width: $bp-x-large) {
        //padding-top: 120px;
        padding-bottom: 120px;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        //padding-top: 100px;
        padding-bottom: 100px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        //padding-top: 80px;
        padding-bottom: 80px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        //padding-top: 30px;
        padding-bottom: 30px;
    }
    @media (max-width: $bp-x-small) {
        //padding-top: 40px;
        padding-bottom: 40px;
    }
    a{
        color: inherit;
        text-decoration: none;
    }

    .section-title{
        @media (min-width: $bp-x-large) {
            padding-top: 140px;
            padding-bottom: 80px;
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            padding-top: 140px;
            padding-bottom: 80px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            padding-top: 120px;
            padding-bottom: 70px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-top: 100px;
            padding-bottom: 30px;
        }
        @media (max-width: $bp-x-small) {
            padding-top: 80px;
            padding-bottom: 30px;
        }
    }

    
    .logos-row{
        @media (min-width: $bp-x-large) {
            padding-top: 120px;
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            padding-top: 100px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            padding-top: 80px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-top: 30px;
        }
        @media (max-width: $bp-x-small) {
            padding-top: 40px;
        }
        &.logos-row-2{
            padding-top: 0px;
            @media (min-width: $bp-medium-min){
                margin-top: 30px;
            }
        }
    }
   
    &.have-title{
        .logos-row{
            padding-top: 0px !important;
        }
    }
    .item{
        //transform: translateY(20px);
        transition: $logo_ease;
        text-align: center;
        transform: translate3d(0,0,0) translateZ(0);
        backface-visibility: hidden;
        backdrop-filter: none;
        -webkit-perspective-origin-x: 1000px;
        -webkit-perspective-origin-y: 1000px;

        img{
            max-width: 100%;
            transition: 0s;
            transform: translate3d(0,0,0) translateZ(0);
            backface-visibility: hidden;
            backdrop-filter: none;
            -webkit-perspective-origin-x: 1000px;
            -webkit-perspective-origin-y: 1000px;
        }
        &.in{
            transform: translateY(0);
        }
        &.out{
            opacity: 0;
            transform: $vertical_Space;
        }
    }


}



@keyframes mymove {
  0%  { opacity: 0 }
  25% { opacity: 1 }
  80% { opacity: 1 }
  100% { opacity: 0 }
}