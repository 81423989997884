/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";
@import "./fonts.scss";


.faq-section{
	@media (min-width: $bp-x-large) {
        padding-top: 140px;
		padding-bottom: 140px;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-top: 130px;
		padding-bottom: 140px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding-top: 120px;
		padding-bottom: 120px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 120px;
		padding-bottom: 120px;
    }
    @media (max-width: $bp-x-small) {
        padding-top: 60px;
		padding-bottom: 60px;
    }
    .main-title{
        margin-bottom: 55px;
    }
    .title{
        margin-bottom: 42px;
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            margin-bottom: 30px;
        }
        @media (max-width: $bp-x-small) {
            margin-bottom: 33px;
        }
    }

    .faq-inner:not(:last-child){
        margin-bottom: 30px;
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            margin-bottom: 20px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            margin-bottom: 20px;
        }
        @media (max-width: $bp-x-small) {
            margin-bottom: 20px;
        }
    }
    .faq-inner {
        //margin-bottom: 30px;
        max-width: calc(8/12 * 100%);
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            max-width: calc(9/12 * 100%);
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            max-width: calc(10/12 * 100%);
            //margin-bottom: 20px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            max-width: calc(10/12 * 100%);
            //margin-bottom: 20px;
        }
        @media (max-width: $bp-x-small) {
            max-width: 100%;
            //margin-bottom: 20px;
        }
        > *:not(:last-child){
            @media (min-width: $bp-large-min){
                margin-bottom: 30px !important;
            }
            @media (max-width: $bp-medium-max) {
                margin-bottom: 20px;
            }
        }
        > *:last-child {
            margin-bottom: 0 !important;
        }
    }

    .cta-holder {
        &:not(:last-child){
            margin-bottom: 30px;
            @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
                max-width: calc(9/12 * 100%);
            }
            @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                margin-bottom: 20px;
            }
            @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                margin-bottom: 20px;
            }
            @media (max-width: $bp-x-small) {
                margin-bottom: 20px;
            }
        }
    }
    .item{
        border-bottom: 1px solid rgba($color-black, 0.2);
        padding-top: 10px;
        padding-bottom: 25px;
        
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
          //  padding-bottom: 5px;
        }
        @media (max-width: $bp-x-small) {
            padding-bottom: 10px;
        }
        &:first-child{
            border-top: 1px solid rgba($color-black,0.2);
        }

        &.active{
            //border-bottom: 0px;
        }
    }
    .item-toggler{
        border: 0px;
        background: transparent;
        display: block;
        width: 100%;
        text-align: left;
        position: relative;
        padding: 25px 0 15px;
        z-index: 2;
        color: $color-black;
        padding-right: calc(2/12 * 100%);
        
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            padding-right: calc(2/12 * 100%);
            padding-top: 30px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-right: calc(2/12 * 100%);
            padding-top: 30px;
        }

        @media (max-width: $bp-x-small) {
            padding-right: calc(2/12 * 100%);
            padding-top: 12px;
            padding-bottom: 10px;
        }
        &:focus{
            outline: none;
        }
        
        .plus-icon{
            display: inline-block;
            position: absolute;
            right: 0;
            top: 22px;
            //transform: translateY(-50%);
            width: 55px;
            height: 55px;
            @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                top: 29px;
            }
            @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                top: 30px;
                width: 42px;
                height: 42px;
            }

            @media (max-width: $bp-x-small) {
                top: 15px;
                width: 29px;
                height: 29px;

            }
            &:before, &:after{
                content: "";
                display: inline-block;
                position: absolute;
                right: 0px;
                top: 0;
                //transform: translateY(-50%);
                background-size: 55px 55px;
                background-position: center;
                width: 55px;
                height: 55px;
                background-image: url(./../ui/icon-minus-desktop.svg);
                transition: transform 500ms ease;
                transform-origin: center;

                @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                    top: calc(50% + -1px);
                }
                @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                    background-size: 42px 42px;
                    width: 42px;
                    height: 42px;
                    top: calc(50% + -1px);
                }
                @media (max-width: $bp-x-small) {
                    background-size: 29px 29px;
                    width: 29px;
                    height: 29px;
                    top: calc(50%);

                }
            }
            &:after {
              transform: rotate(90deg);
                @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                    transform: translateY(-50%) rotate(90deg);

                }
                @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                    transform: translateY(-50%) rotate(90deg);
                }
                @media (max-width: $bp-x-small) {
                    transform: translateY(-50%) rotate(90deg);
                }
            }
            &:before {
              transform: rotate(0deg);
                @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                    transform: translateY(-50%) rotate(0deg);

                }
                @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                    transform: translateY(-50%) rotate(0deg);
                }
                @media (max-width: $bp-x-small) {
                    transform: translateY(-50%) rotate(0deg);
                }
            }
        }
        

        &.active{
            .plus-icon{
                &:before{
                    /*opacity: 0;
                    visibility: hidden;*/
                    transform: rotate(-180deg);
                    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                        transform: translateY(-50%) rotate(-180deg);

                    }
                    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                        transform: translateY(-50%) rotate(-180deg);
                    }
                    @media (max-width: $bp-x-small) {
                        transform: translateY(-50%) rotate(-180deg);
                    }
                }
                &:after{
                    /*opacity: 1;
                    visibility: visible;*/
                    transform: rotate(0deg);
                    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                        transform: translateY(-50%) rotate(0deg);

                    }
                    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                        transform: translateY(-50%) rotate(0deg);
                    }
                    @media (max-width: $bp-x-small) {
                        transform: translateY(-50%) rotate(0deg);
                    }
                }
            }
        }
    }
    .ReactCollapse--collapse{
        transition: all 0.35s ease;
    }
    .accordion-body{
        padding-top: 15px;
        padding-bottom: 35px;
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            padding-top: 5px;
            padding-bottom: 28px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-top: 5px;
            padding-bottom: 28px;
        }

        @media (max-width: $bp-x-small) {
           padding-top: 10px;
           padding-bottom: 20px;
        }
    }

}