/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";


.relatednews-section{
	@media (min-width: $bp-x-large) {
        padding-top: 140px;
		padding-bottom: 140px;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-top: 140px;
		padding-bottom: 140px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding-top: 120px;
		padding-bottom: 120px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 100px;
		padding-bottom: 100px;
    }
    @media (max-width: $bp-x-small) {
        padding-top: 60px;
		padding-bottom: 60px;
    }

    .main-titles{
        padding-bottom: 80px;

        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
	        padding-bottom: 60px;
	    }
	    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
	        padding-bottom: 50px;
	        
	    }
    	@media (max-width: $bp-x-small) {
    		padding-bottom: 40px;
        }
	    p:not(:last-child):not(.caption){
	    	margin-bottom: 0px;
	    }
        .title{
        	max-width: 645px;
	        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
		        max-width: 610px;
		    }
		    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
		        max-width: 100%;
		        
		    }
	    	@media (max-width: $bp-x-small) {
	    		max-width: 100%;
		    	
		    }
        	&:not(:first-child){
        		margin-top: 20px;
	        	@media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
			        margin-top: 20px;
			    }
			    @media (max-width: $bp-x-small) {
			    	margin-top: 10px;
			    }
        	}
        }
        .button {
            margin-top: 20px;
        }

    }
    .item {
        backface-visibility: hidden;
        perspective: 1000;
        a{
            color: inherit;
            text-decoration: none;
        }
        @media (min-width: $bp-x-large) {
            padding-bottom: 80px;
            &:last-child, &:nth-last-child(2){
                padding-bottom: 0px;
            }
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            padding-bottom: 80px;
            &:last-child, &:nth-last-child(2){
                padding-bottom: 0px;
            }
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            padding-bottom: 80px;
            &:last-child, &:nth-last-child(2){
                padding-bottom: 0px;
            }
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-bottom: 60px;
            &:last-child{
                padding-bottom: 0px;
            }
            
        }
        @media (max-width: $bp-x-small) {
            padding-bottom: 40px;
            &:last-child{
                padding-bottom: 0px;
            }
        }
        .item-thumb {
            background: no-repeat center center transparent;
            background-size: cover;
            width: 100%;
            padding-top: calc((317 / 546) * 100%);
        }
        .titles{
            padding-top: 30px;
            position: relative;
            @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                padding-top: 40px;
            }
            @media (max-width: $bp-x-small) {
                padding-top: 20px;
            }
            .desc{
                @media (min-width: $bp-x-large){
                    padding-right: 30px;
                }
                @media (min-width: $bp-medium-min){
                    opacity: 0;
                    position: absolute;
                    visibility: hidden;
                    top: 40px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                }
                @media (max-width: $bp-small-max) {
                    display: none;
                } 
            }
            .title{
                @media (min-width: $bp-x-large){
                    padding-right: 100px;
                }
            }

          

            &.with-summary:hover {
                @media (min-width: $bp-large-min){
                    .title{
                        opacity: 0 !important;
                        visibility: hidden;
                    }
                    .desc{
                        opacity: 1 !important;
                        visibility: visible;
                    }
                }
            }
        }
    }
}