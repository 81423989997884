/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";
@import "~slick-carousel/slick/slick.css";


.quoteslider-section{
    padding-bottom: 50px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    @media(max-width: $bp-x-small){
        padding-bottom: 10px;
    }
    &:focus{
        outline: none !important;
    }
    *{
        &:focus{
            outline: none !important;
        }
    }
    .slick-track{
        @media(max-width: $bp-small-max){
            display: flex;
            .slick-slide{
                display: flex;
                align-self: center;
            }
        }
    }
    .quote-carousel{
        @media (min-width: $bp-x-large) {
            padding-top: 140px;
            padding-bottom: 70px; // -50
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            padding-top: 120px;
            padding-bottom: 70px;  // - 50
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            padding-top: 125px;
            padding-bottom: 70px;  // -50
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-top: 100px;
            padding-bottom: 50px;  // -50
        }
        @media (max-width: $bp-x-small) {
            padding-top: 50px;
            padding-bottom: 50px; // -50
        }
    }
        	
    a{
    	color: inherit;
    	text-decoration: none;
    }
    
    .item-content{
        width: 100%;
        height: 100%;        
    }
    /*.slider-cont{
        @media (min-width: $bp-medium-min){
            cursor: none;
            *{
                cursor: none;
            }
        }
    }*/
    .slick-slide {
        transition: 0s !important;
        @media (min-width: $bp-medium-min){
            opacity: 1 !important;
        }
        .item-content{
            @media (min-width: $bp-medium-min){
                opacity: 0;
                &.active{
                    opacity: 1;
                }
            }
                
        }
    }
    .quote{
        @media (min-width: $bp-x-large) {
            margin-bottom: 40px;
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            margin-bottom: 40px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            margin-bottom: 40px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            margin-bottom: 30px;
        }
        @media (max-width: $bp-x-small) {
            margin-bottom: 20px; 
        }
    }

    .slick-arrow{
        position: absolute;
        top: 0px;
        height: calc( 100% - 0px );
        width: 100%;
        border: 0px;
        background-color: transparent;
        z-index: 999;
        color: transparent;
        font-size: 0px;
        &:focus{
            outline: none;
        }

        &.slick-prev{
            right: 50%;
        }
        &.slick-next{
            left: 50%;
        }
    }
    .slick-dots{
        overflow: hidden;
        padding-left: 0px;
        margin-bottom: 0px;
        font-size: 1em;
        line-height: 1;
        margin-bottom: -7.5px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0px;
        cursor: pointer;
        @media (min-width: $bp-x-large) {
            bottom: -50px;
        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            bottom: -50px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            bottom: -50px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            bottom: -50px;
        }
        @media (max-width: $bp-x-small) {
            bottom: -10px;
        }

        li{
            display: inline-block;
            list-style-type: none;
            font-size: 1em;
            line-height: 1;
            cursor: pointer;
            button{
                font-size: 0px;
                width: 23px;
                height: 23px;
                border: 0px;
                padding: 0px;
                margin: 0px;
                background: transparent;
                position: relative;
                cursor: pointer;

                &:after{
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $color-orange;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                &:focus{
                    outline: none;
                }
            }

            &.slick-active, &:hover{
                button{
                    &:after{
                        background-color: $color-blue;
                    }
                }
            }
        }
        
    }
    

}