/*-----------------------------------------------------------------------------------*/ 
/*  0. include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";
@import "./fonts.scss";

/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables
/*-----------------------------------------------------------------------------------*/
// Right Panel
//---------------------------------------------------------------
// Right Panel Duration in MS
$menu-right-duration: 700; 
// Right Panel Delay in MS
$menu-right-delay: 0; 
$menu-right-easing: cubic-bezier(0.47,0.19,0.16,0.95);

 // Main Nav ( top nav ) Fade-in Duration in MS
$menu-right-main-nav-duration: 200;
// Main Nav ( top nav ) Fade-in Delay in MS
$menu-right-main-nav-delay: 200;
$menu-right-main-nav-easing: cubic-bezier(0.47,0.19,0.16,0.95);

// Secondary Nav ( bottom nav ) Fade-in Duration in MS
$menu-right-secondary-nav-duration: 200; 
// Secondary Nav ( bottom nav ) Fade-in Delay in MS
$menu-right-secondary-nav-delay: 300; 
$menu-right-secondary-nav-easing: cubic-bezier(0.47,0.19,0.16,0.95);

// On Menu Close - Main Nav (top nav)
// Duration - Easing - Delay
$menu-right-main-nav-close: 200ms cubic-bezier(0.47,0.19,0.16,0.95) 100ms;
// On Menu Close - Secondary Nav (Bottom nav)
$menu-right-secondary-nav-close: 200ms cubic-bezier(0.47,0.19,0.16,0.95) 100ms;

//---------------------------------------------------------------
// Left Panel
//---------------------------------------------------------------
// Left Panel slideDown Duration in MS
$menu-left-duration: 700; 
// Left Panel slideDown Delay in MS
$menu-left-delay: 70; 
$menu-left-easing: cubic-bezier(0.47,0.19,0.16,0.95);

// Content Fade-in Duration in MS
$menu-left-content-duration: 200; 
// Content Fade-in Delay in MS
$menu-left-content-delay: 400; 
$menu-left-content-easing: cubic-bezier(0.47,0.19,0.16,0.95);

// On Menu Close - Fade-out duration in MS
// Duration - Easing - Delay
$menu-left-content-close: 200ms cubic-bezier(0.47,0.19,0.16,0.95) 50ms;

//---------------------------------------------------------------
// Mobile
//---------------------------------------------------------------
// Mobile Top content (Navs) SlideDown distance
$menu-mobile-content-distance: 50%; 

// Mobile Blue background SlideDown duration
$menu-mobile-blue-background-duration: 600; 
// Mobile Blue background SlideDown delay
$menu-mobile-blue-background-delay: 0; 
 // Mobile Blue background easing
$menu-mobile-blue-background-easing: cubic-bezier(0.47,0.19,0.16,0.95);


/*-----------------------------------------------------------------------------------*/ 
/*  1. Header
/*-----------------------------------------------------------------------------------*/
// Header + slide down speed and easing
$header-slide: 1200; // in MS
$header-slide-easing: cubic-bezier(.54,0,0,1);

.main-header {
    &.home-animation {
        .headroom-wrapper {
            transform: translateY(-100%);
        }
        &.animated {
            .headroom-wrapper {
                transform: translateY(0%);
                transition: #{$header-slide}ms $header-slide-easing;
            }
        }
    }
}

.fixed-header {
    .headroom {
        position: fixed !important;
        top: 0px !important;
        left: 0px !important;
        right: 0px !important;
        z-index: 1 !important;
        transform: translate3d(0px, 0px, 0px) !important;
        transition: background 0.2s 0s, transform 0s ease-in-out 0s !important;
        .header-content .inner-content{
            &:after {
                display: block !important;
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                opacity: 1;
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
    }
    &.background-black{
        background-color: $color-black !important;
    }
    &.background-white{
        .headroom {
            background-color: $color-white !important;
        }
    }
    &.background-beige{
        .headroom {
            background-color: $color-beige !important;
        }
    }
    &.background-yellow{
        .headroom {
            background-color: $color-yellow !important;
        }
    }
    &.background-saumon{
        .headroom {
            background-color: $color-saumon !important;
        }
    }
    &.background-orange{
        background-color: $color-orange !important;
        &:not(.border-item):not(.quoteslider-section):not(header):not(.fixed-section) + .background-orange{
            padding-top: 0px !important;
        }
    }
    &.background-green{
        .headroom {
            background-color: $color-green !important;
        }
    }
    &.background-blue{
        .headroom {
            background-color: $color-blue !important;
        }
    }
    .main-header {
        &.transparent {
            .headroom {
                background-color: transparent !important;
            }
        }
    }
}

.main-header {
    position: relative;
    z-index: 999;
    .header-content {
        @media (min-width: $bp-medium-min) {
            height: 90px;
        }
        @media (max-width: $bp-small-max) {
            height: 65px;
        }
        @media (max-width: $bp-medium-max){
            background-color: transparent;
            transition: background 0.2s 0s;
        }
        position: relative;
        z-index: 2;
        .container, .row {
            height: 100%;
        }
        .inner-content {
            width: 100%;
            height: 100%;
            position: relative;
            &:after {
                content:"";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                opacity: 1;
                background-color: rgba($color-black, 0.2);
                @media (min-width: $bp-medium-min) {
                    transition: opacity 0.35s 0.55s;
                }
                @media (max-width: $bp-small-max) {
                    transition: opacity 0.35s 0.35s;
                }
            }
        }
        .col-instagram, .col-menu {
            padding-left: 5px;
        }
        .col-navigation {
            padding-right: 15px;
        }
    }
    
    .header-wrapper{
        height: 90px;

        .header-fixed-wrap {

            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            transition: background 0.2s 0.2s, transform 0.2s;
            transform: translateY(0);
            background-color: $color-beige;
        }
        &.header-pinned {
            .header-fixed-wrap{
                background-color: $color-white;
                transition: background 0s 0s, transform 0.2s;
                .header-content {
                    .inner-content:after {
                        display: none;
                    }
                }
            }
        }
        &.header-unpinned {
            .header-fixed-wrap{
                transition: background 0.2s 0s, transform 0.2s;
            }
        }
        &.coming{
            .header-fixed-wrap {
                transform: translateY(0)
            }
        }
        &.leaving{
            .header-fixed-wrap {
                transform: translateY(-100%);
            }
        }
    }

    &.background-yellow{
        .header-wrapper{
            .header-fixed-wrap {
                background-color: $color-yellow;
            }
        }
    }
    &.background-orange{
        .header-wrapper{
            .header-fixed-wrap {
                background-color: $color-orange;
            }
        }
    }
    &.background-green{
        .header-wrapper{
            .header-fixed-wrap {
                background-color: $color-green;
            }
        }
    }
    &.background-blue{
        .header-wrapper{
            .header-fixed-wrap {
                background-color: $color-blue;
            }
        }
    }
    &.background-saumon{
        .header-wrapper{
            .header-fixed-wrap {
                background-color: $color-saumon;
            }
        }
    }

    
    .headroom {
        @media (max-width: $bp-small-max) {
            width: 100vw;
        }
        //background-color: $color-beige;
        transition: background 0.2s 0.4s, transform 0.2s ease-in-out 0s;
        &.headroom--pinned {
            background-color: $color-white;
            transition: background 0s 0s;
            .header-content {
                .inner-content:after {
                    display: none;
                }
            }
        }
        &.headroom--unfixed {
            transition: background 0.2s 0s;
        }
    }
    #page-title {
        position: absolute;
        left: 00px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        display: block;
        width: 360px; //300px;
        color: $color-black;
        transition: color 0.35s 0.55s;
        @media (max-width: $bp-x-small){
            width: calc(100vw - 110px);
        }
    }
    #logo {
        display: block;
        position: relative;
        @media (min-width: $bp-medium-min) {
            width: 80px;
            height: 15px;
        }
        @media (max-width: $bp-small-max) {
            width: 65px;
            height: 12px;   
        }
        &:before, &:after {
            content:"";
            position: absolute;
            top: 0;
            left: 0;
            background: no-repeat url(./../ui/logo.svg) center center transparent;
            @media (min-width: $bp-medium-min) {
                width: 80px;
                height: 15px;
                background-size: 80px 15px;
                transition: opacity 0.35s 0.55s;
            }
            @media (max-width: $bp-small-max) {
                width: 65px;
                height: 12px;
                background-size: 65px 12px;    
                transition: opacity 0.35s 0.35s;
            }
        }
        &:before {
            z-index: 1;
        }
        &:after {
            opacity: 0;
            z-index: 2;
            background-image: url(./../ui/logo-white.svg);
        }
        &.hover-ready {
            &.title-view {
                &:before, &:after {
                    visibility: hidden;
                }
                #page-title {
                    opacity: 1;
                }
                &:hover {
                    &:before, &:after {
                        visibility: visible;
                    }
                    #page-title {
                        opacity: 0;
                    }
                }
            }
        }
    }
    .instagram-btn, .main-nav {
        opacity: 1;
    }
    .instagram-btn {
        display: block;
        width: 20px;
        height: 20px;
        background: no-repeat url(./../ui/insta.svg) center center transparent;
        background-size: contain;
        transition: background 0s 0s, opacity 0.25s 0.65s;
        &:hover {
            background-image: url(./../ui/insta-blue.svg);
        }
    }
    .main-nav {
        transition: 0.25s 0.65s;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                float: left;
                &:not(:last-child){
                    margin-right: 5px;
                }
                a {
                    padding: 34px 10px;
                    height: 90px;
                    display: block;
                    color:$color-black;
                    position: relative;
                    @include typography(1.125, 1.333, normal, -0.27, $main-font);
                    text-decoration: none !important;
                    &:after {
                        //content:"";
                        position: absolute;
                        left: 10px;
                        width: calc(100% - 20px);
                        bottom: 0;
                        //height: 1px;
                        height: 0px;
                        transform: translate3d(0,0,0);
                        background-color: $color-blue;
                        opacity: 0;
                    }
                    &:hover {
                        color: $color-blue;
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .navigation-trigger {
        position: relative;
        display: block;
        outline: 0 none !important;
        border: 0 none !important;
        @media (min-width: $bp-medium-min) {
            width: 60px;
            height: 60px;
            transition: border-radius 0.35s, background 0.25s 0.65s;
        }
        @media (max-width: $bp-small-max) {
            width: 45px;
            height: 45px; 
            transition: border-radius 0.35s, background 0.35s 0.35s;
        }
        border-radius: 50%;
        background-color: $color-green;
        &:before, &:after {
            content:"";
            position: absolute;
            left: 50%;
            @media (min-width: $bp-medium-min) {
                width: 27px;
            }
            /*@media (max-width: $bp-small-max) {
                width: 21px;
            }*/
            height: 1px;
            transform: translate3d(-50%,-50%,0) scale(1, 1.0001) scaleY(1.6);
            transform-origin: center center;
            background-color: $color-black;
            transition:  transform 0.35s 0s, margin 0.35s 0.35s, top 0.35s 0.35s, bottom 0.35s 0.35s, width 0.35s 0.35s;
            @media (max-width: $bp-small-max) {
                width: 21px;
                transform: translate3d(-50%,-50%,0) scale(1, 1.0001) scaleY(1.5);

            }

        }
        &:before {
            @media (min-width: $bp-medium-min) {
                top: 26px;
            }
            @media (max-width: $bp-small-max) {
                top: 50%;
                margin-top: -3px;
            }
        }
        &:after {
            @media (min-width: $bp-medium-min) {
                bottom: 26px;
            }
            @media (max-width: $bp-small-max) {
                bottom: 50%;
                margin-bottom: -3px;
            }
        }
        &:hover {
            @media (min-width: $bp-large-min) {
                border-radius: 0;
                &:before, &:after {
                    //width: 21px;
                }
            }
        }
    }
    .menu-wrapper {
        &.hidden {
            opacity: 0 !important;
        }
        @media (min-width: $bp-medium-min){
            height: 100vh;
        }
        @media (max-width: $bp-small-max){
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            position: fixed;
        }
        width: 100%;
        overflow: hidden;
        position: absolute;
        z-index: 1;
        .container, .row {
            @media (min-width: $bp-medium-min){
                height: 100%;
            }
            @media (max-width: $bp-small-max){
                height: auto;
            }
        }
        @media (max-width: $bp-small-max){
            > .container {
                position: relative;
                z-index: 2;
                .row {
                    position: relative;
                    z-index: 3;
                }
                &:before {
                    content: "";
                    width: 100%;    
                    height: 100%;
                    min-height: 200vh;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $color-blue;
                    opacity: 0;
                    z-index: 1;
                    transform: translateY(-50%);
                    transition: opacity 0s 0s;
                }
                &:after {
                    content: "";
                    width: 100%;    
                    height: 100%;
                    min-height: 100vh;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $color-blue;
                    z-index: 2;
                    transform: translateY(-100%);
                    transition: transform 0.2s 0.35s;
                }
            }
        }
        .right-side, .left-side {
            position: relative;
            @media (min-width: $bp-medium-min){
                height: 100%;
                transform: translateY(-100%);
            }
            @media (max-width: $bp-small-max){
                //transform: translateY(-50%);
                transform: translateY(-#{$menu-mobile-content-distance});
            }
            &:after {
                content: "";
                width: 50vw;
                height: 100%;
                position: absolute;
                top: 0;
                background-color: $color-blue;
                z-index: 1;
                transform: translateZ(0) scaleX(1.002);
                @media (max-width: $bp-small-max){
                    display: none;
                }
            }
            .row {
                position: relative;
                z-index: 2;
            }
        }
        .right-side {
            @media (min-width: $bp-large-min){
                padding-top: 150px;
            }
            @media (max-width: $bp-small-max){
                padding-top: 110px;
            }
            //transition: transform 0.65s 0.2s;
            transition: transform #{$menu-left-duration}ms $menu-right-easing #{$menu-left-delay}ms;
            &:after {
                @media (min-width: $bp-medium-min){
                    left: -25px;
                    width: calc(50vw + 50px);
                }
                @media (max-width: $bp-small-max){
                    left: 0;
                }
            }
        }
        .left-side {
            @media (max-width: $bp-small-max){
                padding-top: 60px;
                width: 100%;
            }
            //transition: transform 0.85s 0s;
            transition: transform #{$menu-right-duration}ms $menu-left-easing #{$menu-right-delay}ms;
            &:after {
                @media (min-width: $bp-medium-min){
                    right: 25px;
                }
                @media (max-width: $bp-small-max){
                    right: 0;
                }
            }
            .row {
                opacity: 0;
                //transition: opacity 0.25s 0s;
                transition: opacity $menu-left-content-close;
                p {
                    color: $color-white;
                    @media (max-width: $bp-small-max){
                        margin-bottom: 40px !important;
                    }
                    a {
                        color: inherit;
                        text-decoration: none !important;
                        border-bottom: 1px solid $color-orange !important;
                        &:hover {
                            color: $color-orange !important;
                        }
                    }
                }
            }
        }
        nav {
            opacity: 0;
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    margin: 0;
                    padding: 0;
                }
            }
            a {
                color: $color-white;
                display: inline-block;
                text-decoration: none !important;
                &:hover {
                    @media (min-width: $bp-medium-min){
                        color: $color-yellow;
                    }
                }
                &.active {
                    color: $color-yellow;
                }
            }
            &.menu-nav-main {
                //transition: opacity 0.3s 0.2s;
                transition: opacity $menu-right-main-nav-close;
                a {
                    position: relative;
                    padding-right: 20px;
                    transition: color 0.35s;
                    @media (min-width: $bp-x-large) {
                        @include typography(5.625, 1, normal, -0.25, $heading-font);
                    }
                    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
                        @include typography(5.000, 1.038, normal, -0.2, $heading-font); 
                    }
                    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                        @include typography(4.375, 1, normal, -0.21, $heading-font);
                    }
                    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                        @include typography(3.875, 1.032, normal, -0.15, $heading-font);
                    }
                    @media (max-width: $bp-x-small) {
                        @include typography(2.438, 1.154, normal, -0.1, $heading-font);
                    }
                    &:after {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        opacity: 0;
                        background-image: url(./../ui/icon-arrow-giant-arrow-desktop-light.svg);
                        background-position: center;
                        left: 100%;
                        transform: translate(-10px, -50%);
                        transition: 0.35s;
                        top: 50%;
                        @media (min-width: $bp-large-min){
                            background-size: 74px 75px;
                            width: 75px;
                            height: 75px;
                        }
                        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                            background-size: 52px 52px;
                            background-position: center;
                            width: 52px;
                            height: 52px;
                        }
                        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                            display: none;
                            background-size: 52px 52px;
                            background-position: center;
                            width: 52px;
                            height: 52px;
                        }
                        @media (max-width: $bp-x-small) {
                            display: none;
                            background-size: 30px 30px;
                            background-position: center right;
                            width: 30px;
                            height: 30px;
                            background-repeat: no-repeat
                        }
                    }
                    &:hover {
                        &:after {
                            opacity: 1;
                            transform: translate(0px, -50%);
                        }
                    }
                }
            }
            &.menu-nav-secondary {
                @media (min-width: $bp-large-min){
                    padding-top: 40px;
                }
                @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max){
                    padding-top: 30px;
                }
                @media (min-width: $bp-small-min) and (max-width: $bp-small-max){
                    padding-top: 40px;
                }
                @media (max-width: $bp-x-small){
                    padding-top: 30px;
                }
                //transition: opacity 0.3s 0.25s;
                transition: opacity $menu-right-secondary-nav-close;
                a {
                    transition: color 0.35s;
                    @media (min-width: $bp-x-large) {
                        @include typography(2.5, 1.25, normal, -0.8, $main-font);
                    }
                    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
                        @include typography(2.5, 1.25, normal, -0.8, $main-font);
                    }
                    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                        @include typography(2.25, 1.306, normal, -0.72, $main-font);
                    }
                    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                        @include typography(2.125, 1.324, normal, -0.7, $main-font);
                    }
                    @media (max-width: $bp-x-small) {
                        @include typography(1.563, 1.36, normal, -0.3, $main-font);
                    }
                }
            }
        }
    }
    .social-links {
        overflow: hidden;
        @media (min-width: $bp-large-min){
            padding-bottom: 50px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max){
            padding-bottom: 40px;
            max-width: 180px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-bottom: 60px;
        }
        @media (max-width: $bp-x-small) {
            padding-bottom: 40px;
        }
        .social-link {
            display: block;
            float: left;
            width: 62px;
            height: 62px;
            border-radius: 50%;
            text-decoration: none !important;
            background: no-repeat center center $color-saumon;
            &:not(:last-child){
                margin-right: 10px;
            }
            @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max){
                &:nth-child(n+3){
                    margin-top: 10px;
                }
            }
            &.facebook {
                background-image: url(./../ui/social-facebook.svg);
            }
            &.twitter {
                background-image: url(./../ui/social-twitter.svg);
            }
            &.instagram {
                background-image: url(./../ui/social-insta.svg);
            }
            &.linkedin {
                background-image: url(./../ui/social-linkedin.svg);
            }
            &:hover {
                background-color: $color-orange;
            }
        }
    }
    &.menu-active {
        .headroom {
            @media (max-width: $bp-small-max){
                position: fixed !important;
                transform: translateY(0%) !important;
            }
        }
        #logo {
            &.title-view {
                &:before, &:after {
                    visibility: visible;
                    transition: opacity 0s 0s;
                }
                #page-title {
                    opacity: 0;
                }
            }
            &:not(.title-view) {
                &:hover {
                    &:before, &:after {
                        visibility: visible;
                    }
                    #page-title {
                        opacity: 0;
                    }
                }
            }
            #page-title {
                color: $color-white;
                transition: color 0.25s 0.25s;
            }
            &:before, &:after {
                transition: opacity 0.25s 0s;
            }
            &:before {
                opacity: 0;
            }
            &:after {
                opacity: 1;
            }
        }
        .header-content {
            @media (max-width: $bp-medium-max){
                background-color: $color-blue;
                transition: background 0.15s 0.5s;
            }
            .inner-content {
                &:after {
                    opacity: 0;
                    transition: opacity 0.25s 0s;
                }
            }
        }
        .instagram-btn, .main-nav {
            transition: opacity 0.25s 0s, visibility 0s 0.25s;
            visibility: hidden;
            opacity: 0;
        }
        .navigation-trigger {
            background-color: $color-orange;
            &:before {
                top: 50%;
                transform: translate3d(-50%,-50%,0) scale(1, 1) rotate(45deg) scaleY(1.9);
            }
            &:after {
                bottom: 50%;
                transform: translate3d(-50%,50%,0) scale(1, 1) rotate(-45deg) scaleY(1.9);
            }
            &:before, &:after {
                transition: transform 0.35s 0.35s, margin 0.35s 0s, top 0.35s 0s, bottom 0.35s 0s, width 0.35s 0s;
            }
            @media (min-width: $bp-large-min) {
                border-radius: 0;
                &:before, &:after {
                    //width: 21px;
                }
            }
            @media (min-width: $bp-medium-min) {
                border-radius: 0;
                transition: border-radius 0.35s, background 0.35s;
            }
            @media (max-width: $bp-small-max){
                border-radius: 0;
                transition: border-radius 0.35s, background 0.35s;
                &:before, &:after {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }
        .menu-wrapper {
            overflow-y: auto;
            scroll-behavior: smooth;
            -webkit-overflow-scrolling: touch;
            @media (max-width: $bp-small-max){
                background-color: $color-blue;
                transition: background 0.85s;
                > .container {
                    &:after {
                        //transition: transform 0.35s 0s;
                        transition: transform #{$menu-mobile-blue-background-duration}ms $menu-mobile-blue-background-easing #{$menu-mobile-blue-background-delay}ms;
                        transform: translateY(0);
                    }
                    &:before {
                        transition: opacity 0s 0.4s;
                        opacity: 1;
                    }
                }
            }
            .right-side, .left-side {
                transform: translateY(0%);
            }
            .left-side {
                //transition: transform 0.65s 0.2s;
                transition: transform #{$menu-left-duration}ms $menu-left-easing #{$menu-left-delay}ms;
                .row {
                    opacity: 1;
                    //transition: opacity 0.45s 0.65s;
                    transition: opacity #{$menu-left-content-duration}ms $menu-left-content-easing #{$menu-left-content-delay}ms;
                }
            }
            .right-side {
                //transition: transform 0.85s 0s;
                transition: transform #{$menu-right-duration}ms $menu-right-easing #{$menu-right-delay}ms;
            }
            nav {
                opacity: 1;
                &.menu-nav-main {
                    //transition: opacity 0.5s 0.3s;
                    transition: opacity #{$menu-right-main-nav-duration}ms $menu-right-main-nav-easing #{$menu-right-main-nav-delay}ms;
                }
                &.menu-nav-secondary {
                    //transition: opacity 0.45s 0.35s;
                    transition: opacity #{$menu-right-secondary-nav-duration}ms $menu-right-secondary-nav-easing #{$menu-right-secondary-nav-delay}ms;
                }
            }
        }
    }
}

.fixed-headroom {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    transform: translateY(0%) !important;
}


.main-title{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    left: 20px;
    opacity: 0;
    transition: 0.2s ease;
    &.active{
        opacity: 1;
    }

    &:hover{
        opacity: 0;
        transition: 0s;
        & + #logo{
            opacity: 1;
            transition: 0s;
        }
    }
}

#logo{
    &.hide{
        opacity: 0;

        /*&.hovering{
            transition: 0s;
            opacity: 1;

            & + .main-title{
                opacity: 0;
                transition: 0s;
            }
        }*/
    }
}

.os-windows {
    #menu, .header-content {
        transition: padding 0s 0.005s;
    }
    .menu-active {
        #menu, .header-content {
            padding-right: 17px;
            transition: padding 0s 0.01s;
        }
    }
}

