/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";



.page-contact{

	.sidedcta-section{
		@media (min-width: $bp-x-large) {
			padding-bottom: 80px;
	    }
	    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
			padding-bottom: 80px;
	    }
	    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
			padding-bottom: 80px;
	    }
	    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
			padding-bottom: 70px;
	    }
	    @media (max-width: $bp-x-small) {
			padding-bottom: 60px;
	    }

	    & + .largeslider-section{
		  	@media (min-width: $bp-x-large) {
		        padding-top: 80px;
		    }
		    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
		        padding-top: 80px;
		    }
		    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
		        padding-top: 80px;
		    }
		    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
		        padding-top: 70px;
		    }
		    @media (max-width: $bp-x-small) {
		        padding-top: 60px;
		    }
		}
	}

	
}