/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";


.top-section{
	@media (min-width: $bp-x-large) {
        //padding-top: 230px;
		//padding-bottom: 80px;
        padding-top: 150px; // -90px
        padding-bottom: 70px;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        //padding-top: 240px;
		//padding-bottom: 80px;
        padding-top: 150px;
        padding-bottom: 70px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding-top: 110px;
		padding-bottom: 60px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 110px;  
		padding-bottom: 50px;
    }
    @media (max-width: $bp-x-small) {
        padding-top: 60px;
		padding-bottom: 30px;
    }
    h1 {
        transform-origin: left bottom ;
        & + *:not(.filter-holder) {
            margin-top: 15px;
        }

        & + .medium-title{
            @media (min-width: $bp-medium-min){
                margin-top: 25px;
            }
            @media (min-width: $bp-small-min) and ( max-width: $bp-small-max) {
                margin-top: 20px;
            }
            @media (max-width: $bp-x-small){
                margin-top: 15px;
            }
        }
    }
    .page-title{
        transform-origin: left bottom;
        will-change: transform;
        backface-visibility: hidden;
        perspective: 1000;
    }
    .filter-holder {
        margin-top: 30px;
        padding-left: 0px;
        margin-bottom: 0px;
        li{
            list-style-type: none;
            display: inline-block;
            margin-top: 10px;
            &:not(:last-child){
                margin-right: 7px;
            }
        }
        a {
    
        }
    }

    &.with-filter{
        padding-bottom: 0px !important;
    }
}