/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";


.biglist-section{
	@media (min-width: $bp-x-large) {
        padding-top: 130px;
		padding-bottom: 140px;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-top: 130px;
		padding-bottom: 140px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding-top: 120px;
		padding-bottom: 120px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 100px;
		padding-bottom: 100px;
    }
    @media (max-width: $bp-x-small) {
        padding-top: 60px;
		padding-bottom: 60px;
    }

    .main-titles{
        padding-bottom: 70px;

        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
	        padding-bottom: 60px;
	    }
	    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
	        padding-bottom: 50px;
	        
	    }
    	@media (max-width: $bp-x-small) {
    		padding-bottom: 40px;
	    }
	    p:not(:last-child):not(.caption) {
	    	margin-bottom: 0px;
	    }
        .title{
        	max-width: 645px;
	        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
		        max-width: 610px;
		    }
		    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
		        max-width: 100%;
		        
		    }
	    	@media (max-width: $bp-x-small) {
	    		max-width: 100%;
		    	
		    }
        	&:not(:first-child){
        		margin-top: 20px;
	        	@media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
			        margin-top: 20px;
			    }
			    @media (max-width: $bp-x-small) {
			    	margin-top: 10px;
			    }
        	}
        	
        }

    }
    
    
    
    .item{
    	border-top: 1px solid $color-beige;
    	//padding-top: 12px;
    	//padding-bottom: 8px;

        padding-top: 8px;
        padding-bottom: 12px;
    	position: relative;
        transition: 0.3s ease;

    	@media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
    		padding-top: 9px;
    		padding-bottom: 12px;
    	}
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            padding-top: 11px;
            padding-bottom: 11px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-bottom: 14px;
			padding-top: 14px;
        }
        @media (max-width: $bp-x-small) {
            padding-bottom: 10px;
    		padding-top: 10px;
        }

    	&:last-child{
    		border-bottom: 1px solid $color-beige;
    	}
    	.item-title, .icon-wrap{
    		display: inline-block;
    		vertical-align: middle;
            //transition: 0.3s ease;
    	}
        
    	.item-title{
    		padding-left: 30px;
            padding-top: 5px;
            padding-bottom: 5px;
            transition: 0.3s ease;
	    	@media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
	    		padding-top: 10px;
	    	}

	    	@media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
	    		padding-top: 4px;
	    	}
    		@media (max-width: $bp-small-max){
    			padding-left: 0;
    		}
            @media (max-width: $bp-x-small){
                padding-top: 0px;
                padding-bottom: 3px;
            }

    	}
    	.icon-wrap{
    		width: 112px;
    		height: 112px;
    		text-align: center;
    		@media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
	    		width: 101px;
	    		height: 101px;
	    	}
    		.icon{
    			display: inline-block;
    			position: relative;
    			vertical-align: middle;
    			background-position: center center;
    			background-size: contain;
    			background-repeat: no-repeat; 
    			width: 100%;
    			height: 100%;

    			&.circle{
    				background-image: url(./../ui/big-link-1.svg);
    				background-size: 96px 96px;
    				@media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
			    		background-size: 87px 87px;
			    	}
    			}
    			&.hexa{
    				background-image: url(./../ui/big-link-2.svg);
    				background-size: 112px 97px;
    				@media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
			    		background-size: 101px 87.5px
			    	}
    			}
    			&.rect{
    				background-image: url(./../ui/big-link-3.svg);
    				background-size: 92px 90px;
    				@media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
			    		background-size: 82px 80.5px;
			    	}
    			}
    			&.triangle{
    				background-image: url(./../ui/big-link-4.svg);
    				background-size: 97px 97px;
    				@media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                        background-size: 82px 80px;
			    	}
    			}
    		}
    		@media (max-width: $bp-small-max){
    			display: none;
    		}
    	}
    	a{
    		color: inherit;
    		text-decoration: none;
    		display: block;
            position: relative;
    	}
    	a:after{
    		content: "";
    		display: inline-block;
    		position: absolute;

    		background-image: url(./../ui/icon-arrow-giant-arrow-desktop.svg);
    		background-size: 74px 75px;
    		background-position: center;
    		right: 0px;
    		transform: translateY(-50%);
    		top: 50%;
		    width: 75px;
		    height: 75px;
            transition: 0.3s ease;
            @media (min-width: $bp-medium-min){
                right: 20px;
            }
        	@media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
		    	background-size: 52px 52px;
			    background-position: center;
			    width: 52px;
			    height: 52px;
		    }
		    @media (max-width: $bp-x-small) {
	            background-size: 30px 30px;
			    background-position: center right;
			    width: 30px;
			    height: 30px;
			    background-repeat: no-repeat
	        }
    	}
        
        &:hover{
           
        }
    }

    
    &.background-saumon{
    	.icon-wrap{
            .icon{
                &.circle{
                    background-image: url(./../ui/big-link-1.svg);
                }
                &.hexa{
                    background-image: url(./../ui/big-link-2.svg);
                }
                &.rect{
                    background-image: url(./../ui/big-link-3.svg);
                }
                &.triangle{
                    background-image: url(./../ui/big-link-4.svg);
                }
            }
            
        }
    }

    &.background-orange{
        .icon-wrap{
            .icon{
                &.circle{
                    background-image: url(./../ui/big-link-1-saumon.svg);
                }
                &.hexa{
                    background-image: url(./../ui/big-link-2-saumon.svg);
                }
                &.rect{
                    background-image: url(./../ui/big-link-3-saumon.svg);
                }
                &.triangle{
                    background-image: url(./../ui/big-link-4-saumon.svg);
                }
            }
            
        }
    }

    .hovering{
        @media (min-width: $bp-medium-min){
            .item{
                //opacity: 0.2;
                .icon-wrap, .item-title,  a:after{
                    //opacity: 0.2;
                }
                .icon-wrap{
                        transition: all 0.3s ease;
                }
                &.hover{
                   
                    /*.icon-wrap, .item-title,  a:after{
                        opacity: 1;
                    }*/
                    .item-title {
                        padding-left: 50px; // 30 + 20
                    }
                    a:after {
                        right: 0px;
                    }

                    .icon-wrap{
                        .icon{
                            &.circle{
                                background-image: url(./../ui/big-link-1-blue.svg);
                            }
                            &.hexa{
                                background-image: url(./../ui/big-link-2-green.svg);
                            }
                            &.rect{
                                background-image: url(./../ui/big-link-3-yellow.svg);
                            }
                            &.triangle{
                                background-image: url(./../ui/big-link-4-white.svg);
                            }
                        }
                    }

                }
            }
        }
            
    }



}


.page-home{
    .biglist-section {
        .item{
            border-color: $color-saumon;
            &:last-child{
                border-color: $color-saumon;
            }
        }
    }
}