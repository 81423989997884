/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";


.single-wrapper{
	overflow: hidden;
    @media (min-width: $bp-x-large) {
        padding-top: 90px;
        padding-bottom: 140px;

    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-top: 90px;
        padding-bottom: 120px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding-top: 105px;
        padding-bottom: 100px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media (max-width: $bp-x-small) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    /*.singleintro-section{
        &:first-child{
            margin-top: 0px;
        }
        &:last-child{
            margin-bottom: 0px;
        }
        @media (min-width: $bp-x-large) {
            //margin-top: 100px;
            margin-bottom: 100px;

        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            //margin-top: 100px;
            margin-bottom: 100px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            //margin-top: 100px;
            margin-bottom: 80px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            //margin-top: 100px;
            margin-bottom: 60px;
        }
        @media (max-width: $bp-x-small) {
            //margin-top: 100px;
            margin-bottom: 60px;
        }
    }*/

    
    .singleintro-section, .paragraph-section, .image-section, .video-section, .media-section, .introtext-section{
        &:first-child:not(.no-topname){
            margin-top: 0px;
        }
        &:not(.news-intro){
            &:last-child{
                margin-bottom: 0px;
            }
            @media (min-width: $bp-x-large) {
                //margin-top: 100px;
                margin-bottom: 100px;

            }
            @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
                //margin-top: 100px;
                margin-bottom: 100px;
            }
            @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                //margin-top: 100px;
                margin-bottom: 80px;
            }
            @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                //margin-top: 100px;
                margin-bottom: 60px;
            }
            @media (max-width: $bp-x-small) {
                //margin-top: 100px;
                margin-bottom: 40px;
            }
        }
        &.news-intro {
            
            @media (min-width: $bp-x-large) {
                //margin-top: 100px;
                margin-bottom: 40px;
            }
            @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
                //margin-top: 100px;
                margin-bottom: 40px;
            }
            @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                //margin-top: 100px;
                margin-bottom: 40px;
            }
            @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                //margin-top: 100px;
                margin-bottom: 40px;
            }
            @media (max-width: $bp-x-small) {
                //margin-top: 100px;
                margin-bottom: 30px;
            }
        }
    }

    .singleintro-section{

        & + .image-section {
            @media (max-width: $bp-x-small) {
                //margin-top: -20px;
            }
        }
        &.no-topname{
            margin-top: 50px;
        }

        &.only-title{
            @media (min-width: $bp-x-large) {
                margin-bottom: 60px;
            }
            @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
                margin-bottom: 60px;
            }
            @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                margin-bottom: 50px;
            }
            @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                margin-bottom: 50px;
            }
            @media (max-width: $bp-x-small) {
                margin-bottom: 40px;
            }
        }
    }

    &.introtext{
        .singleintro-section{
            margin-bottom: 0px;
        }
    }
    .cta-with-title-section{
        padding-top: 0px;
        padding-bottom: 0px;
        &:first-child{
            margin-top: 0px;
        }
        &:last-child{
            margin-bottom: 0px;
        }
        @media (min-width: $bp-x-large) {
            margin-top: 140px;
            margin-bottom: 140px;

        }
        @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
            margin-top: 140px;
            margin-bottom: 100px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            margin-top: 100px;
            margin-bottom: 100px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            margin-top: 80px;
            margin-bottom: 80px;
        }
        @media (max-width: $bp-x-small) {
            margin-top: 60px;
            margin-bottom: 60px;
        }
    }

    .dragableslider-section{
        padding-top: 0px;
        //padding-bottom: 0px;
            &:last-child{
                padding-bottom: 0px;
            }
            @media (min-width: $bp-x-large) {
                //margin-top: 100px;
                padding-bottom: 100px;

            }
            @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
                //margin-top: 100px;
                padding-bottom: 100px;
            }
            @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                //margin-top: 100px;
                padding-bottom: 80px;
            }
            @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                //margin-top: 100px;
                padding-bottom: 60px;
            }
            @media (max-width: $bp-x-small) {
                //margin-top: 100px;
                padding-bottom: 40px;
            }
    }

    .introtext-section{
    
        &:first-child{
            margin-top: 0px;
        }
        &:last-child{
            margin-bottom: 0px;
        }

        //margin-top: 40px;
        margin-bottom: 40px;

        @media (min-width: $bp-medium-min){
            margin-top: 25px;
        }
        @media (min-width: $bp-small-min) and ( max-width: $bp-small-max) {
            margin-top: 20px;
        }
        @media (max-width: $bp-x-small){
            margin-top: 15px;
        }
    }
}