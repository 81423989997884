/*-----------------------------------------------------------------------------------*/ 
/*  0. include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";
@import "./fonts.scss";

/*-----------------------------------------------------------------------------------*/ 
/*  1. Footer
/*-----------------------------------------------------------------------------------*/

@mixin footerLink() {
    position: relative;
    color: $color-black !important;
    display: inline;
    padding-right: 0px;
    border-bottom: 1px solid transparent !important;
    &:after {
        content: "";
        display: inline-block;
        position: absolute;
        background: no-repeat url(./../ui/icon-arrow-bigbutton-mobile-blue.svg) right center transparent;
        left: 100%;
        opacity: 0;
        transform: translate(-10px, -50%);
        transition: 0.35s;
        top: 50%;
        @media (min-width: $bp-large-min){
            background-size: 16px 16px;
            width: 26px;
            height: 16px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            background-position: center;
            background-size: 15px 15px;
            width: 17px;
            height: 17px;
        }
        @media (max-width: $bp-small-max) {
            display: none;
        }
    }
    &:hover, &:active {
        color: $color-blue !important;
        border-bottom: 1px solid $color-blue !important;
        text-decoration: none !important;
    }
    &:hover {
        &:after {
            opacity: 1;
            transform: translate(0px, -50%);
        }
    }
}

.main-footer {
    position: relative;
    @media (min-width: $bp-x-large) {
        padding: 100px 0 90px 0;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding: 100px 0 90px 0;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding: 80px 0 90px 0;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding: 60px 0 80px 0;
    }
    @media (max-width: $bp-x-small) {
        padding: 40px 0 60px 0;
    }
    .footer-insta {
        width: 100%;
        @media (min-width: $bp-large-min) {
            padding-bottom: 100px;
            margin-bottom: 70px;
        }
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            padding-bottom: 80px;
            margin-bottom: 70px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-bottom: 60px;
            margin-bottom: 60px;
        }
        @media (max-width: $bp-x-small) {
            padding-bottom: 40px;
            margin-bottom: 40px;
        }
        border-bottom: 3px solid $color-yellow;
        a {
            display: block;
            color: $color-black;
            text-decoration: none !important;
            position: relative;
            @media (min-width: $bp-large-min) {
                @include typography(5.313, 0.918, normal, -0.26, $heading-font); 
            }

            @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
                @include typography(5.00, 1.0375, normal, -0.2, $heading-font);
            }
            @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                @include typography(4.500, 1.139, normal, -0.2, $heading-font);
            }
            @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                @include typography(3.875, 1.161, normal, -0.17, $heading-font);
            }
            @media (max-width: $bp-x-small) {
                @include typography(2.438, 1.143, normal, -0.11, $heading-font);
                padding-right: 30px;
            }
            &:after {
                content: "";
                display: inline-block;
                position: absolute;
                background-image: url(./../ui/icon-arrow-giant-arrow-desktop.svg);
                background-repeat: no-repeat;
                background-position: right center;
                right: 0%;
                transform: translate(0px, -50%);
                transition: 0.3s ease;
                top: 50%;
                @media (min-width: $bp-large-min){
                    background-size: 74px 75px;
                    width: 75px;
                    height: 75px;
                    right: 20px;
                }
                @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                    background-size: 74px 75px;
                    width: 75px;
                    height: 75px;
                }
                @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                    background-size: 52px 52px;
                    width: 52px;
                    height: 52px;
                }
                @media (max-width: $bp-x-small) {
                    background-size: 30px 30px;
                    width: 30px;
                    height: 30px;
                }
            }
            &:hover {
                &:after {
                    right: 0;
                }
            }
        }
    }
    .col-subscription {
        .medium-title {
            //max-width: 435px;
            a {
                color: $color-black;
                display: inline;
                border-bottom: 1px solid $color-blue;
                &:hover, &:active {
                    color: $color-blue;
                    border-bottom: 1px solid $color-blue;
                    text-decoration: none !important;
                }
            }
        }
        .form-holder {
           //max-width: 435px;
           position: relative;
            margin-top: 40px;
            padding-bottom: 15px;
            border-bottom: 1px solid $color-blue;
            @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                margin-bottom: 60px;
            }
            @media (max-width: $bp-x-small) {
                margin-bottom: 30px;
            }
            input {
                @media (min-width: $bp-large-min) {
                    @include typography(1, 1.5625, normal, -0.1, $main-font);
                }
                @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                    @include typography(1, 1.5625, normal, -0.1, $main-font);
                }
                @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                    @include typography(1, 1.5625, normal, -0.1, $main-font);
                }
                @media (max-width: $bp-x-small) {
                    @include typography(1, 1.5625, normal, -0.1, $main-font);
                }
                width: calc(100% - 30px);
                height: 26px;
                padding: 0;
                background: transparent none !important;
                border-radius: 0 !important;
                box-shadow: inherit !important;
                border: inherit !important;
                outline: inherit !important;
                color: $color-black;
                &::placeholder {
                    color: $color-black;
                } 
                &:-ms-input-placeholder {
                    color: $color-black;
                }
                &::-ms-input-placeholder {
                    color: $color-black;
                }
                &:hover, &:focus {
                    color: $color-black;
                    &::placeholder {
                        color: $color-black;
                    } 
                    &:-ms-input-placeholder {
                        color: $color-black;
                    }
                    &::-ms-input-placeholder {
                        color: $color-black;
                    }
                }
            }
            button {
                position: absolute;
                top: 5px;
                right: 0;
                background: no-repeat url(./../ui/icon-arrow-bigbutton-mobile-blue.svg) right center transparent;
                transition: 0.35s;
                background-size: 16px 16px;
                width: 26px;
                height: 16px;
                border-radius: 0 !important;
                box-shadow: inherit !important;
                border: inherit !important;
                outline: inherit !important;
                text-indent: -99999px;
            }
        }
    }
    .col-location, .col-social {
        h4 {
            border-bottom: 1px solid rgba($color-black, 0.2);
            @media (min-width: $bp-large-min) {
                margin-bottom: 18px;
                padding-bottom: 2px;
            }
            @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
                padding-bottom: 2px;
                margin-bottom: 25px;
            }
            @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
                padding-bottom: 2px;
                margin-bottom: 28px;
            }
            @media (max-width: $bp-x-small) {
                padding-bottom: 2px;
                margin-bottom: 23px;
            }
        }
    }
    .col-location {
        p {
            a {
                @include footerLink();
            }
        }
    }
    .col-social {
        nav {
            ul {
                padding: 0;
                margin: 0;
                list-style: none;
                li {
                    padding: 0;
                    margin: 0;
                    a {
                        @include footerLink();
                    }
                }
            }
        }
    }
}