/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";


.sidedcta-section{
	@media (min-width: $bp-x-large) {
        padding-top: 140px;
		padding-bottom: 140px;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-top: 120px;
		padding-bottom: 120px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
        padding-top: 120px;
		padding-bottom: 120px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 100px;
		padding-bottom: 100px;
    }
    @media (max-width: $bp-x-small) {
        padding-top: 60px;
		padding-bottom: 60px;
    }

    .titles{
        padding-top: 30px;
        position: relative;

    }
    .image-wrapper{
        position: relative;

    }
    .image-item{
        padding-top: 117.064%;
        background-color: $color-yellow;
        background-position: center;
        background-size: cover;
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-top: 100%;
        }
        @media (max-width: $bp-x-small) {
            padding-top: 119.5%;
        }
    }
    .button-wrapper{
        position: absolute;
        bottom: 40px;
        left: 40px;
        @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
            bottom: 30px;
            left: 30px;
        }
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            bottom: 30px;
            left: 30px;
        }
        @media (max-width: $bp-x-small) {
            bottom: 20px;
            left: 20px;
        }
    }
    
    .item{
        @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
            padding-bottom: 40px;
            &:last-child{
                padding-bottom: 0px;
            }
        }
        @media (max-width: $bp-x-small) {
            padding-bottom: 20px;
            &:last-child{
                padding-bottom: 0px;
            }
        }
        a{
            color: inherit;
            text-decoration: none;
        }
        
        &:hover{
            .button{
                &.big-btn{
                    @media (min-width: $bp-large-min) {
                        padding-right: 75px;
                    }
                    @media (min-width: $bp-small-min) and (max-width: $bp-medium-max) {
                        padding-right: 75px;
                    }
                }


                text-decoration: none;
                @media (min-width: $bp-small-min) {
                    &:after{
                      opacity: 0;
                    }
                    &:before{
                      opacity: 1
                    }
                }
            }
                  
                
        }
    }


    .placeholder-img{
        opacity: 0;
        width: 100%;
        height: auto;
    }
    .lazy-holder {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img{
            /*height: 100%;
            width: 100%;*/

            min-height: 100%;
            min-width: 100%;
            object-fit: cover;
            width: auto;
            max-width: 100%;
            height: auto;
        }
    }

}