/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";


.singleintro-section{
    p.client-name {
        margin-bottom: 20px !important;
    }
    .title{
        & + .intro-text {
            //margin-top: 40px;
            @media (min-width: $bp-large-min) and (max-width: $bp-large-max ){
                padding-right: calc(1/11 * 100%);
            }
            @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max ){
                padding-right: calc(1/11 * 100%);
            }
            

            @media (min-width: $bp-medium-min){
                margin-top: 25px;
            }
            @media (min-width: $bp-small-min) and ( max-width: $bp-small-max) {
                margin-top: 20px;
            }
            @media (max-width: $bp-x-small){
                margin-top: 15px;
            }
        }
    }

    .tags-holder {
        margin-top: 20px;
        padding-left: 0px;
        margin-bottom: 0px;
        li{
            list-style-type: none;
            display: inline-block;
            margin-top: 10px;
            &:not(:last-child){
                margin-right: 7px;
            }
        }
        a {
    
        }
    }


}