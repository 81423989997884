/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";
@import "~slick-carousel/slick/slick.css";


.shapes-section{
    position: relative;
    padding-top: 0px;
    padding-bottom: 0px;
    /*@media (min-width: $bp-x-large) {
        padding-top: 140px;
        padding-bottom: 140px;
    }
    @media (min-width: $bp-large-min) and (max-width: $bp-large-max) {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    @media (min-width: $bp-medium-min) and (max-width: $bp-medium-max) {
         padding-top: 120px;
        padding-bottom: 120px;
    }
    @media (min-width: $bp-small-min) and (max-width: $bp-small-max) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media (max-width: $bp-x-small) {
        padding-top: 60px;
        padding-bottom: 60px;
    }*/

   
    .w-video-wrapper{
        background-color: transparent !important;
    }
    .shapeholder{
        padding-top: 100%;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        .hovered-shape{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            display: none;
        }
        &:hover{
            .hovered-shape{
                display: block;
            }
        }
    }
    .shape-item{
        .player-wrapper {
          position: relative;
          padding-top: 100%; /* Player ratio: 100 / (1280 / 720) */
          overflow: hidden;
          z-index: 2;
        }
         
        .react-player {
          position: absolute;
          top: 0;
          left: 0;
          pointer-events: none;
        }
        .poster-img{
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 100%;
            height: 100%;
            z-index: 5;
            background-size: cover;
            background-repeat: no-repeat;

            &.hide{
                opacity: 0;
                visibility: hidden;
            }
        }
        .poster-img__ {
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: calc(100% + 2px) !important;
            height: 100%;
            z-index: 1;
            background-size: cover;
            background-repeat: no-repeat;
        }
        & > div{
            width: calc(1/3 * 100vw) !important;
            height: calc(1/3 * 100vw) !important;
        }
       
        .wistia_embed{
            width: calc(1/3 * 100vw) !important;
            height: calc(1/3 * 100vw) !important;
        }
        *{
            //width: calc(1/3 * 100vw) !important;
            //height: calc(1/3 * 100vw) !important;
        }
    }
    
    
}