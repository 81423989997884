/*-----------------------------------------------------------------------------------*/ 
/*  0. Variables include
/*-----------------------------------------------------------------------------------*/
@import "./variables.scss";

.stickyimages-section {
    position: relative;
    .index-1 {
        z-index: 10
    }
    .index-2 {
        z-index: 9
    }
    .index-3 {
        z-index: 8
    }
    .index-4 {
        z-index: 7
    }
    .index-5 {
        z-index: 6
    }
    .sticky-parent, .container {
        position: relative;
    }
    .sticky-image-holder {
        width: 100%;
        bottom: -30vw;
        @media (min-width: $bp-x-large){
            margin-bottom: 13vh;
        }
        @media (max-width: $bp-large-max) and (min-width: $bp-large-min){
            margin-bottom: 10vh;
        }
        @media (max-width: $bp-medium-max) and (min-width: $bp-medium-min){
            margin-bottom: 10vh;
        }
        @media (max-width: $bp-small-max) and (min-width: $bp-small-min){
            margin-bottom: 7vh;
        }
        @media (max-width: $bp-x-small){
            margin-bottom: 5vh;
        }
        &:first-child {
            transform: translateY(0);
        }
        &:nth-child(2) {
            bottom: -10vw;
        }
        &:nth-child(3) {
            bottom: -20vw;
        }
        &:nth-child(4) {
            bottom: -25vw;
        }
        &:nth-child(5) {
            bottom: -25vw;
        }
        &:last-child {
            @media (max-width: $bp-small-max){
                margin-bottom: 60px;
            }
        }
        position: sticky;
        .sticky-image {
            img {
                width: 100%;
                max-width: 100%;
            }
        }
    }
    .col-content {
        position: relative;
        z-index: 100;
        @media (min-width: $bp-large-min){
            transform: translateY(-20vh);
        }
        @media (max-width: $bp-medium-max) and (min-width: $bp-medium-min){
            margin-bottom: 120px;
        }
        @media (max-width: $bp-small-max){
            margin-bottom: 60px;
        }
    }
}